import { useNavigate } from "react-router-dom";
import {
  ColumnContainer,
  Label,
  Panel,
  SafePanel,
  LimitedContainer,
  Space,
  Button,
  RowContainer,
  Image,
  ContainerButton,
  ScrollContainer,
  LimitedContainerLarge,
  LabelXXL,
  LabelL,
  LabelS,
  ResponsiveIcon,
  PriceTable,
  TableRow,
  TableCell,
  TableHeader,
} from "../components/Ui/Ui";
import {
  rentPageCarPriceData,
  rentPageMainData,
  rentPageFleetData,
  rentBedingungen,
  rentPageBedingungData,
} from "../data/RentPageData";
import { Helmet } from "react-helmet-async";
import { NavToContactPage, scrollToTop } from "../utils/utilities";
import { dataByKey } from "../data/NavBarData";
import { RentPageMetaData } from "../data/MetaData";
import { srcData } from "../data/SrcData";
const Rent = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>{RentPageMetaData.title}</title>
        {RentPageMetaData.meta.map((meta, index) => (
          <meta
            key={index}
            {...(meta.property
              ? { property: meta.property }
              : { name: meta.name })}
            content={meta.content}
          />
        ))}
        <link rel="canonical" href={RentPageMetaData.canonical} />
        <script type="application/ld+json">
          {JSON.stringify(RentPageMetaData.structuredData)}
        </script>
      </Helmet>
      <Panel style={{ background: "white" }}>
        <SafePanel normal={false} allowMargin={false}>
          <ColumnContainer>
            <LimitedContainer>
              <LabelXXL color="black" fontWeight="900">
                {rentPageMainData.title}
              </LabelXXL>
            </LimitedContainer>
            <LimitedContainer>
              <Label color="black">{rentPageMainData.description}</Label>
            </LimitedContainer>
            <Space style={{ height: "2rem" }} />
            <LimitedContainer>
              <Button
                onClick={() => {
                  NavToContactPage(navigate, dataByKey.contact.to);
                  scrollToTop();
                }}
              >
                Jetzt Reservieren
              </Button>
            </LimitedContainer>
          </ColumnContainer>
        </SafePanel>
      </Panel>
      <Panel style={{ background: "black" }}>
        <SafePanel>
          <ColumnContainer style={{ width: "100%" }}>
            <RowContainer sizing={false}>
              <LabelXXL color="white" fontWeight="900">
                {rentPageFleetData.title}
              </LabelXXL>

              <Space style={{ width: "0.5rem" }} />
              {(() => {
                const Icon = rentPageFleetData.icon;
                return (
                  <ResponsiveIcon size="4rem">
                    <Icon
                      fill="white"
                      viewBox="-2 -2 28 28"
                      style={{
                        alignSelf: "center",
                        strokeWidth: "1",
                        stroke: "white",
                      }}
                    />
                  </ResponsiveIcon>
                );
              })()}
            </RowContainer>
            <Space style={{ height: "2rem" }} />
            <ColumnContainer
              style={{ height: "100%", justifyContent: "center" }}
            >
              <RowContainer style={{ justifyContent: "center", gap: "3rem" }}>
                <ColumnContainer style={{ gap: "1rem" }}>
                  {rentPageFleetData.fleet.map((element, index) => (
                    <ColumnContainer key={index}>
                      <LimitedContainer>
                        <LabelL color="white" fontWeight="600">
                          {element.title}
                        </LabelL>
                      </LimitedContainer>
                      <LimitedContainer>
                        <Label color="white">{element.description}</Label>
                      </LimitedContainer>
                    </ColumnContainer>
                  ))}
                </ColumnContainer>
                <ColumnContainer style={{ justifyContent: "center" }}>
                  <Image
                    src={srcData.transporter1.localuri}
                    alt="Transporter"
                  />
                </ColumnContainer>
              </RowContainer>
            </ColumnContainer>
          </ColumnContainer>
        </SafePanel>
      </Panel>
      <Panel style={{ background: "red" }}>
        <SafePanel>
          <ColumnContainer>
            <RowContainer sizing={false}>
              <LabelXXL color="white" fontWeight="900">
                {rentPageCarPriceData.title}
              </LabelXXL>
              <Space style={{ width: "0.5rem" }} />
              {(() => {
                const Icon = rentPageCarPriceData.icon;
                return (
                  <ResponsiveIcon size="4rem">
                    <Icon
                      fill="white"
                      viewBox="-2 -2 28 28"
                      style={{
                        alignSelf: "center",
                        strokeWidth: "1",
                        stroke: "white",
                      }}
                    />
                  </ResponsiveIcon>
                );
              })()}
            </RowContainer>
            <Space style={{ height: "3rem" }} />
            <LimitedContainerLarge>
              <Label color="white">{rentPageCarPriceData.description}</Label>
            </LimitedContainerLarge>
            <Space style={{ height: "2rem" }} />
            <RowContainer
              style={{
                justifyContent: "center",
                gap: "2rem",
                alignItems: "center",
              }}
            >
              <ColumnContainer>
                <LabelL
                  color="white"
                  fontWeight="900"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {rentPageCarPriceData.wochentariftitle}
                </LabelL>
                <Label
                  color="white"
                  fontWeight="normal"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {rentPageCarPriceData.wochentarif}
                </Label>
                <PriceTable>
                  {rentPageCarPriceData.wochenTarif.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.art}</TableCell>
                      <TableCell>{item.kilometer}</TableCell>
                      <TableCell>{item.price}</TableCell>
                    </TableRow>
                  ))}
                </PriceTable>
                <RowContainer style={{ flexDirection: "row-reverse" }}>
                  <LabelS
                    color="white"
                    fontWeight="600"
                    style={{
                      opacity: "0.3",
                      marginRight: "0.5rem",
                    }}
                  >
                    {rentPageCarPriceData.standNo}
                  </LabelS>
                </RowContainer>
              </ColumnContainer>
              <ColumnContainer>
                <LabelL
                  color="white"
                  fontWeight="900"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {rentPageCarPriceData.wochenendtariftitle}
                </LabelL>
                <Label
                  color="white"
                  fontWeight="normal"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {rentPageCarPriceData.wochenendtarif}
                </Label>
                <PriceTable>
                  {rentPageCarPriceData.wochenEndtarif.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.art}</TableCell>
                      <TableCell>{item.kilometer}</TableCell>
                      <TableCell>{item.price}</TableCell>
                    </TableRow>
                  ))}
                </PriceTable>
                <RowContainer style={{ flexDirection: "row-reverse" }}>
                  <LabelS
                    color="white"
                    fontWeight="600"
                    style={{
                      opacity: "0.3",
                      marginRight: "0.5rem",
                    }}
                  >
                    {rentPageCarPriceData.standEn}
                  </LabelS>
                </RowContainer>
              </ColumnContainer>
              {/* <ContainerButton>
                <ColumnContainer>
                  <LabelL
                      color="black" fontWeight="900"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {rentPageCarPriceData.wochentariftitle}
                  </LabelL>
                  <LabelS
                    color="black" fontWeight="normal"
                    style={{
                      marginLeft: "0.6rem",
                    }}
                  >
                    {rentPageCarPriceData.wochentarif}
                  </LabelS>
                </ColumnContainer>
              </ContainerButton>
              <ContainerButton>
                <ColumnContainer>
                  <LabelL
                     color="black" fontWeight="900"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {rentPageCarPriceData.wochenendtariftitle}
                  </LabelL>
                  <LabelS
                   color="black" fontWeight="normal"
                    style={{
                      marginLeft: "0.6rem",
                    }}
                  >
                    {rentPageCarPriceData.wochenendtarif}
                  </LabelS>
                </ColumnContainer>
              </ContainerButton> */}
            </RowContainer>
            <Space style={{ height: "2rem" }} />
            <ColumnContainer>
              {rentPageCarPriceData.info.map((element, index) => (
                <LabelS
                  color="white"
                  fontWeight="600"
                  style={{
                    opacity: "0.3",
                  }}
                  key={index}
                >
                  {element.title}
                </LabelS>
              ))}
            </ColumnContainer>
            <Space style={{ height: "12rem" }} />
            <RowContainer sizing={false}>
              <LabelXXL color="white" fontWeight="900">
                {rentPageBedingungData.title}
              </LabelXXL>
              <Space style={{ width: "0.5rem" }} />
              {(() => {
                const Icon = rentPageBedingungData.icon;
                return (
                  <ResponsiveIcon size="4rem">
                    <Icon
                      fill="white"
                      viewBox="-2 -2 28 28"
                      style={{
                        alignSelf: "center",
                        strokeWidth: "1",
                        stroke: "white",
                      }}
                    />
                  </ResponsiveIcon>
                );
              })()}
            </RowContainer>
            <Space style={{ height: "3rem" }} />
            <ScrollContainer>
              {rentBedingungen.map((section, index) => (
                <ColumnContainer key={index}>
                  <LabelL color="white" fontWeight="900" key={index}>
                    {section.title}
                  </LabelL>
                  {section.points.map((point, idx) => (
                    <Label key={idx} color="white">
                      {point}
                    </Label>
                  ))}
                  <Space style={{height:"1rem"}}/>
                </ColumnContainer>
              ))}
            </ScrollContainer>
            </ColumnContainer>
        </SafePanel>
      </Panel>
    </>
  );
};

export default Rent;
