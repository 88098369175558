// BlurredParallaxBackground.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Wrapper for the panel with background
const PanelWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%; /* Ensure it takes the full height of the parent */
  overflow: hidden; /* Hide overflow so that the background doesn't spill out */
`;

// Parallax background layer with blur
const bounceAnimation = `
  @keyframes bounceUpDown {
    0% {
      transform: translate(-50%, -50%) translate(0, 0); /* Start from center */
    }
    25% {
      transform: translate(-50%, -50%) translate(10px, -10px); /* Move diagonally */
    }
    50% {
      transform: translate(-50%, -50%) translate(-20px, 20px); /* Bounce to the other diagonal */
    }
    75% {
      transform: translate(-50%, -50%) translate(10px, -30px); /* Move diagonally again */
    }
    100% {
      transform: translate(-50%, -50%) translate(0, 0); /* Return to the center */
    }
  }
`;

const ParallaxLayer = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: blur(5px); /* Add blur effect */
  z-index: -1; /* Ensure the background stays behind the content */
  pointer-events: none; /* Disable interaction with the background */

  /* Apply the bounce animation */
  animation: bounceUpDown 60s ease-in-out infinite; /* Smooth bouncing movement */
  animation-timing-function: ease-in-out;

  /* Insert the bounce keyframe animation */
  ${bounceAnimation}
`;


// Parallax Background Component
const BlurredParallaxBackground = ({ Icon, duration, movementDistance, children }) => (
  <PanelWrapper>
    {/* Background */}
    <ParallaxLayer
      initial={{ y: 0 }}
      animate={{ y: movementDistance }}
      transition={{ repeat: Infinity, duration: duration, ease: 'linear' }}
    >

  	<Icon style={{ width: '50vw', height: '50vh' }} />
    </ParallaxLayer>

    {/* Content */}
    {children}
  </PanelWrapper>
);

export default BlurredParallaxBackground;