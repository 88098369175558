import { Helmet } from "react-helmet-async";
import { ColumnContainer, Label, LabelL, LabelXXL, LimitedContainer, LimitedContainerMedium, Panel, SafePanel, Space } from "../components/Ui/Ui";
import { aboutUsPageMainData } from "../data/AboutUsData";
import { AboutUsPageMetaData } from "../data/MetaData";
const AboutUs = () => {
  return (
    <>
       <Helmet>
        <title>{AboutUsPageMetaData.title}</title>
        {AboutUsPageMetaData.meta.map((meta, index) => (
          <meta
            key={index}
            {...(meta.property ? { property: meta.property } : { name: meta.name })}
            content={meta.content}
          />
        ))}
        <link rel="canonical" href={AboutUsPageMetaData.canonical} />
        <script type="application/ld+json">
          {JSON.stringify(AboutUsPageMetaData.structuredData)}
        </script>
      </Helmet>
      <Panel style={{ background: "white" }}>
        <SafePanel>
          <ColumnContainer>
            <LabelXXL color="black" fontWeight="900"
            >
              {aboutUsPageMainData.title}
            </LabelXXL>
            <Space style={{height:"2rem"}}/>
            {aboutUsPageMainData.history.map((element, index) => (
              <ColumnContainer>
                  <LabelL style={{color: "black", fontWeight:"600"}} key={index}>
                    {element.title}
                  </LabelL>
                  <LimitedContainerMedium>
                  <Label style={{color: "black"}} key={index}>
                    {element.description}
                  </Label>
                  </LimitedContainerMedium>
                  <Space style={{height:"2rem"}}/>
                  </ColumnContainer>
              ))}
              <Space style={{height:"2rem"}}/>
     
          </ColumnContainer>
        </SafePanel>
      </Panel>
      <Panel style={{ background: "black" }}>
        <SafePanel>
          <ColumnContainer>
            <LabelXXL
            color="white"
            fontWeight="900"
            >
               {aboutUsPageMainData.zustand}
            </LabelXXL>
            <Space style={{height:"2rem"}}/>
            {aboutUsPageMainData.nextStep.map((element, index) => (
              <ColumnContainer>
                  <Label style={{color: "white", fontSize: "1.5rem", fontWeight:"600"}} key={index}>
                    {element.title}
                  </Label>
                  <LimitedContainerMedium >
                  <Label style={{color: "white"}} key={index}>
                    {element.description}
                  </Label>
                  </LimitedContainerMedium>
                  <Space style={{height:"2rem"}}/>
                  </ColumnContainer>
              ))}
              <Space style={{height:"2rem"}}/>
          </ColumnContainer>
        </SafePanel>
      </Panel>
      <Panel style={{ background: "red" }}>
        <SafePanel>
          <ColumnContainer>
            <LabelXXL color="white" fontWeight="900"
            >
               {aboutUsPageMainData.goal}
            </LabelXXL>
            <Space style={{height:"2rem"}}/>
            {aboutUsPageMainData.goals.map((element, index) => (
              <ColumnContainer>
                  <Label style={{color: "white", fontSize: "1.5rem", fontWeight:"600"}} key={index}>
                    {element.title}
                  </Label>
                  <LimitedContainerMedium >
                  <Label style={{color: "white"}} key={index}>
                    {element.description}
                  </Label>
                  </LimitedContainerMedium>
                  <Space style={{height:"2rem"}}/>
                  </ColumnContainer>
              ))}
              <Space style={{height:"2rem"}}/>
          </ColumnContainer>
        </SafePanel>
      </Panel>
    </>
  );
};

export default AboutUs;
