import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.div`
  background-color: #000000;
  padding: 2rem 4rem 2rem 4rem;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  justify-content: center;
  display: flex;
  @media screen and (max-width: 960px) {
    padding: 1rem 1rem 1rem 1rem;
  }
`;

export const NavLinks = styled.span`
  font-size: 1rem;
  margin-bottom: 8px;
  color: #E2E2E2;
  display: flex;
  align-items: center;
  text-decoration: none;
  &:hover {
	cursor: pointer;
    color: white;
    transition: all 0.3s ease;
  }
  @media screen and (max-width: 960px) {
    /* text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    &:hover {
      color: white;
      transition: all 0.3s ease;
    } */
  }`;
