import {
  homePageMainPanelData,
  homePageSecondryPanelData,
  homePageThirdlyPanelData,
} from "../data/HomePageData";
import { Helmet } from "react-helmet-async";
import {
  ColumnContainer,
  Label,
  Panel,
  SafePanel,
  Button,
  LimitedContainer,
  Space,
  ContainerButton,
  RowContainer,
  LabelXXL,
  LabelL,
  LabelS,
  LimitedContainerLarge,
  ResponsiveIcon,
  CustomMarquee,
} from "../components/Ui/Ui";
import { NavToContactPage, scrollToTop } from "../utils/utilities";
import { useNavigate } from "react-router-dom";
import { dataByKey } from "../data/NavBarData";
import { HomePageMetaData } from "../data/MetaData";
import ParallaxBackground from "../components/Animation/BlurredParallaxBackground";
import BlurredParallaxBackground from "../components/Animation/BlurredParallaxBackground";
import { Icons } from "../components/Icons/Icons";
import Marquee from "react-fast-marquee";
const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>{HomePageMetaData.title}</title>
        {HomePageMetaData.meta.map((meta, index) => (
          <meta
            key={index}
            {...(meta.property
              ? { property: meta.property }
              : { name: meta.name })}
            content={meta.content}
          />
        ))}
        <link rel="canonical" href={HomePageMetaData.canonical} />
        <script type="application/ld+json">
          {JSON.stringify(HomePageMetaData.structuredData)}
        </script>
      </Helmet>
      {/* <BlurredParallaxBackground
          Icon={Icons.transporter} // Pass the icon here directly as a React component
        duration={1} // Speed of the parallax
        movementDistance={100} 
          > */}
          <CustomMarquee speed={10} autoFill={true}>
          <Label color="black" fontWeight="600" style={{margin:"0.5rem"}}>
            {homePageMainPanelData.marquee}
          </Label>
        </CustomMarquee>
      <Panel style={{ background: "transparent" }} center={true}>
        <SafePanel normal={false} allowMargin={false}>
          <ColumnContainer>
            <LimitedContainer>
              <LabelXXL color="black" fontWeight="900">
                {homePageMainPanelData.title}
              </LabelXXL>
            </LimitedContainer>
            <LimitedContainer>
              <Label>{homePageMainPanelData.description}</Label>
            </LimitedContainer>
            <Space style={{ height: "2rem" }} />
            <LimitedContainer>
              <Button
                onClick={() => {
                  NavToContactPage(navigate, dataByKey.contact.to);
                  scrollToTop();
                }}
              >
                {homePageMainPanelData.btn}
              </Button>
            </LimitedContainer>
          </ColumnContainer>
        </SafePanel>
      </Panel>
      {/* </BlurredParallaxBackground> */}
      <Panel style={{ background: "black" }} center={false}>
        <SafePanel normal={true}>
          <ColumnContainer style={{ width: "100%" }}>
            <LabelXXL color="white" fontWeight="900">
              {homePageSecondryPanelData.title}
            </LabelXXL>
            <ColumnContainer
              style={{ gap: "2rem", height: "100%", justifyContent: "center" }}
            >
              <RowContainer
                style={{
                  justifyContent: "center",
                  gap: "2rem",
                  alignItems: "center",
                }}
              >
                {homePageSecondryPanelData.points.map((element, index) => {
                  const Icon = element.icon;
                  return (
                    <ContainerButton
                      onClick={() => {
                        NavToContactPage(navigate, element.to);
                        scrollToTop();
                      }}
                      key={index}
                    >
                      <ColumnContainer>
                        <ResponsiveIcon
                          size="3rem"
                          style={{ justifyContent: "center" }}
                        >
                          <Icon fill="black" style={{ alignSelf: "center" }} />
                        </ResponsiveIcon>
                        <LabelL
                          color="black"
                          fontWeight="900"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {element.title}
                        </LabelL>
                        <LabelS
                          fontWeight="normal"
                          style={{
                            color: "black",
                            marginLeft: "0.6rem",
                          }}
                        >
                          {element.description}
                        </LabelS>
                      </ColumnContainer>
                    </ContainerButton>
                  );
                })}
              </RowContainer>
              {/* <RowContainer style={{ justifyContent: "center", gap: "2rem" }}>
                  <ContainerButton>
                    <ColumnContainer>
                      <Label
                        style={{
                          color: "black",
                          fontWeight: "900",
                          fontSize: "1.5rem",
                          textAlign: "center",
                        }}
                      >
                        Lieferung
                      </Label>
                      <Label
                        style={{
                          color: "black",
                          fontWeight: "normal",
                          fontSize: "0.8rem",
                          marginLeft: "0.6rem",
                        }}
                      >
                        Haben Sie was gekauft und möchten es liefern? wir sind für
                        sie da und liefern in Schleswigholstein und Hamburg
                      </Label>
                    </ColumnContainer>
                  </ContainerButton> 
                </RowContainer> */}
            </ColumnContainer>
          </ColumnContainer>
        </SafePanel>
      </Panel>
      <Panel style={{ background: "red" }}>
        <SafePanel>
          <ColumnContainer style={{ width: "100%" }}>
            <LabelXXL color="white" fontWeight="900">
              {homePageThirdlyPanelData.title}
            </LabelXXL>
            <ColumnContainer style={{ height: "100%" }}>
              <Space style={{ height: "3rem" }} />
              <ColumnContainer style={{ gap: "1rem" }}>
                {homePageThirdlyPanelData.points.map((point, index) => {
                  const Icon = point.icon;
                  return (
                    <RowContainer key={index} sizing={false}>
                      <ColumnContainer style={{ justifyContent: "center" }}>
                        <ResponsiveIcon>
                          <Icon fill="white" />
                        </ResponsiveIcon>
                      </ColumnContainer>
                      <Space style={{ width: "1rem" }} />
                      <ColumnContainer>
                        <LabelL color="white" fontWeight="900">
                          {point.name}
                        </LabelL>
                        <LimitedContainerLarge sizing={false}>
                          <Label color="white">{point.description}</Label>
                        </LimitedContainerLarge>
                      </ColumnContainer>
                    </RowContainer>
                  );
                })}
              </ColumnContainer>
            </ColumnContainer>
          </ColumnContainer>
        </SafePanel>
      </Panel>
    </>
  );
};

export default Home;
