import { Icons } from "../components/Icons/Icons";

export const KfzPageMainData = {
  title: "Vom Motor bis zur Karosserie – Unsere Autoreparatur deckt alles ab.",
  description:
    "Unser umfassender Autoservice bietet eine breite Palette von Dienstleistungen für Ihr Fahrzeug an, um sicherzustellen, dass es in einwandfreiem Zustand bleibt. Von regelmäßigen Wartungen über Inspektionen bis hin zum TÜV-Service kümmern wir uns um alles. Wir stehen bereit, um Ölwechsel, Bremsenwechsel, Filterwechsel und mehr durchzuführen, um die Leistung und Sicherheit Ihres Fahrzeugs zu gewährleisten. Darüber hinaus bieten wir Dienstleistungen wie Getriebeaustausch, Sensorersatz, Klimaservice und vieles mehr an, um sicherzustellen, dass Ihr Fahrzeug stets optimal funktioniert. Verlassen Sie sich auf uns, um Ihr Fahrzeug in Top-Zustand zu halten, damit Sie sicher und sorgenfrei auf der Straße unterwegs sind.",
};

export const KfzPageWerkstattData = {
  title: "Unsere Leistungen",
  icon: Icons.kfz,
  stand: "Stand 01.2025"
};
export const KFZLeistungen = [
  {
    name: "Ölwechsel",
    src: "./assets/images/logo/Ölwechsel.png",
    preis: "ab 120€",
    showPreis: true,
  },
  {
    name: "Reifenwechsel",
    src: "./assets/images/logo/Reifenwechsel.png",
    preis: "ab 30€",
    showPreis: true,
  },
  {
    name: "Inspektion",
    src: "./assets/images/logo/Inspektion.png",
    preis: "ab 200€",
    showPreis: true,
  },
  { name: "HU/AU", src: "./assets/images/logo/HU-AU.png", preis: "ab 170€",   showPreis: true },
  {
    name: "Klimaservice",
    src: "./assets/images/logo/Klimaservice.png",
    preis: "ab 120€",
    showPreis: true,
  },

  {
    name: "Batteriewechsel",
    src: "./assets/images/logo/Batteriewechsel.png",
    preis: "ab 30€",
    showPreis: false,
  },
  {
    name: "Bremsenprüfung",
    src: "./assets/images/logo/Bremsenprüfung.png",
    preis: "ab 30€",
    showPreis: false,
  },
  {
    name: "Fahrzeugdiagnose",
    src: "./assets/images/logo/Fahrzeugdiagnose.png",
    preis: "ab 20€",
    showPreis: false,
  },
  {
    name: "Stoßdämpfer-Service",
    src: "./assets/images/logo/Stoßdämpfer-Service.png",
    preis: "ab 120€",
    showPreis: false,
  },
  {
    name: "Bremsflüssigkeitwechsel",
    src: "./assets/images/logo/Bremsflüssigkeitwechsel.png",
    preis: "ab 35€",
    showPreis: false,
  },
  {
    name: "Motorreparatur",
    src: "./assets/images/logo/Motorreparatur.png",
    preis: "ab 900€",
    showPreis: false,
  },
  {
    name: "Karosseriearbeiten",
    src: "./assets/images/logo/Karosseriearbeiten.png",
    preis: "ab 170€",
    showPreis: false,
  },

  {
    name: "Scheibenreparatur",
    src: "./assets/images/logo/Scheibenreparatur.png",
    preis: "ab 250€",
    showPreis: false,
  },
  {
    name: "Getriebe-Service",
    src: "./assets/images/logo/Getriebe-Service.png",
    preis: "ab 150€",
    showPreis: false,
  },
  {
    name: "Zahnriemenwechsel",
    src: "./assets/images/logo/Zahnriemenwechsel.png",
    preis: "ab 250€",
    showPreis: false,
  },
  {
    name: "Kupplungswechsel",
    src: "./assets/images/logo/Kupplungswechsel.png",
    preis: "ab 350€",
    showPreis: false,
  },
  {
    name: "Abgasanlage-Service",
    src: "./assets/images/logo/Abgasanlage-Service.png",
    preis: "ab 200€",
    showPreis: false,
  },
  {
    name: "Kühlmittelwechsel",
    src: "./assets/images/logo/Kühlmittelwechsel.png",
    preis: "ab 50€",
    showPreis: false,
  },
  {
    name: "Servolenkung-Service",
    src: "./assets/images/logo/Servolenkung-Service.png",
    preis: "ab 50€",
    showPreis: false,
  },
  {
    name: "Felgeninstandsetzung",
    src: "./assets/images/logo/Felgeninstandsetzung.png",
    preis: "ab 20€",
    showPreis: false,
  },
  {
    name: "Parkassistenz-Einbau",
    src: "./assets/images/logo/Parkassistenz-Einbau.png",
    preis: "ab 150€",
    showPreis: false,
  },
  {
    name: "Lichtanlage-Service",
    src: "./assets/images/logo/Lichtanlage-Service.png",
    preis: "ab 50€",
    showPreis: false,
  },

 
  {
    name: "Wintercheck",
    src: "./assets/images/logo/Wintercheck.png",
    preis: "ab 100€",
    showPreis: false,
  },
  {
    name: "Sommercheck",
    src: "./assets/images/logo/Sommercheck.png",
    preis: "ab 100€",
    showPreis: false,
  },
  {
    name: "Unterbodenschutz",
    src: "./assets/images/logo/Unterbodenschutz.png",
    preis: "ab 100€",
    showPreis: false,
  },
  {
    name: "Luftfilterwechsel",
    src: "./assets/images/logo/Luftfilterwechsel.png",
    preis: "ab 20€",
    showPreis: false,
  },
  {
    name: "Zündkerzenwechsel",
    src: "./assets/images/logo/Zündkerzenwechsel.png",
    preis: "ab 50€",
    showPreis: false,
  },
  {
    name: "Fahrzeugpolitur",
    src: "./assets/images/logo/Fahrzeugpolitur.png",
    preis: "ab 300€",
    showPreis: false,
  },
  {
    name: "Kraftstofffilter wechseln",
    src: "./assets/images/logo/Fahrzeugpolitur.png",
    preis: "ab 30€",
    showPreis: false,
  },
  {
    name: "Keilriemen wechseln",
    src: "./assets/images/logo/Fahrzeugpolitur.png",
    preis: "ab 30€",
    showPreis: false,
  },
  {
    name: "Auspuffreparaturen",
    src: "./assets/images/logo/Fahrzeugpolitur.png",
    preis: "ab 100€",
    showPreis: false,
  },
  {
    name: "Rost",
    src: "./assets/images/logo/Fahrzeugpolitur.png",
    preis: "ab 50€",
    showPreis: false,
  },
  {
    name: "Radlager wechseln",
    src: "./assets/images/logo/Fahrzeugpolitur.png",
    preis: "ab 80€",
    showPreis: false,
  },
  {
    name: "Getriebeöl wechseln",
    src: "./assets/images/logo/Fahrzeugpolitur.png",
    preis: "ab 150€",
    showPreis: false,
  },
  {
    name: "Scheibenwischer wechseln",
    src: "./assets/images/logo/Fahrzeugpolitur.png",
    preis: "ab 20€",
    showPreis: false,
  },
  {
    name: "Tür- und Fenstereinstellungen",
    src: "./assets/images/logo/Fahrzeugpolitur.png",
    preis: "ab 150€",
    showPreis: false,
  },
  {
    name: "Turbolader wechseln",
    src: "./assets/images/logo/Fahrzeugpolitur.png",
    preis: "ab 350€",
    showPreis: false,
  },
  {
    name: "Altöl Entsorgung",
    src: "./assets/images/logo/Fahrzeugpolitur.png",
    preis: "ab 10€",
    showPreis: false,
  },
  {
    name: "Reifen Entsorgung",
    src: "./assets/images/logo/Fahrzeugpolitur.png",
    preis: "ab 5€",
    showPreis: false,
  },
  {
    name: "Schrott Entsorgung",
    src: "./assets/images/logo/Fahrzeugpolitur.png",
    preis: "kostenlos",
    showPreis: false,
  },
  // {
  //   name: "Dieselpartikelfilter-Reinigung",
  //   src: "./assets/images/logo/Dieselpartikelfilter-Reinigung.png",
  //   preis: "ab 00€",
  //   showPreis: false,
  // },
  // {
  //   name: "Dashcam-Installation",
  //   src: "./assets/images/logo/Dashcam-Installation.png",
  //   preis: "ab 70€",
  //   showPreis: false,
  // },
  // {
  //   name: "E-Mobil-Service",
  //   src: "./assets/images/logo/E-Mobil-Service.png",
  //   preis: "ab 00€",
  //   showPreis: false,
  // },
  // {
  //   name: "Hybridfahrzeug-Service",
  //   src: "./assets/images/logo/Hybridfahrzeug-Service.png",
  //   preis: "ab 00€",
  //   showPreis: false,
  // },
  // {
  //   name: "Fahrwerksprüfung",
  //   src: "./assets/images/logo/Fahrwerksprüfung.png",
  //   preis: "ab 70€",
  //   showPreis: false,
  // },
  // { name: "Tuning", src: "./assets/images/logo/Tuning.png", preis: "ab 70€",     showPreis: false, },
  // {
  //   name: "Achsvermessung",
  //   src: "./assets/images/logo/Achsvermessung.png",
  //   preis: "ab 80€",
  //   showPreis: false,
  // },
  // {
  //   name: "Standheizung-Einbau",
  //   src: "./assets/images/logo/Standheizung-Einbau.png",
  //   preis: "ab 00€",
  //   showPreis: false,
  // },
  // {
  //   name: "Fahrzeugbewertung",
  //   src: "./assets/images/logo/Fahrzeugbewertung.png",
  //   preis: "ab 00€",
  //   showPreis: false,
  // },
  // {
  //   name: "Schlüsselprogrammierung",
  //   src: "./assets/images/logo/Schlüsselprogrammierung.png",
  //   preis: "ab 00€",
  //   showPreis: false,
  // },
  // {
  //   name: "Smart-Repair",
  //   src: "./assets/images/logo/Smart-Repair.png",
  //   preis: "ab 00€",
  //   showPreis: false,
  // },
  // {
  //   name: "Klimaanlagen-Desinfektion",
  //   src: "./assets/images/logo/Klimaanlagen-Desinfektion.png",
  //   preis: "ab 00€",
  //   showPreis: false,
  // },
  // {
  //   name: "Reifenlagerung",
  //   src: "./assets/images/logo/Reifenlagerung.png",
  //   preis: "ab 00€",
  //   showPreis: false,
  // },
  // {
  //   name: "Glasservice",
  //   src: "./assets/images/logo/Glasservice.png",
  //   preis: "ab 00€",
  //   showPreis: false,
  // },
  // {
  //   name: "Fahrzeugaufbereitung",
  //   src: "./assets/images/logo/Fahrzeugaufbereitung.png",
  //   preis: "ab 00€",
  //   showPreis: false,
  // },
  // {
  //   name: "Abschleppdienst",
  //   src: "./assets/images/logo/Abschleppdienst.png",
  //   preis: "ab 00€",
  //   showPreis: false,
  // },
  // {
  //   name: "Elektronikprüfung",
  //   src: "./assets/images/logo/Elektronikprüfung.png",
  //   preis: "ab 00€",
  //   showPreis: false,
  // },
  // {
  //   name: "Navigationssystem-Einbau",
  //   src: "./assets/images/logo/Navigationssystem-Einbau.png",
  //   preis: "ab 00€",
  //   showPreis: false,
  // },
  // {
  //   name: "Innenraumreinigung",
  //   src: "./assets/images/logo/Innenraumreinigung.png",
  //   preis: "ab 00€",
  //   showPreis: false,
  // },
  // {
  //   name: "Außenreinigung",
  //   src: "./assets/images/logo/Außenreinigung.png",
  //   preis: "ab 00€",
  //   showPreis: false,
  // },
  // {
  //   name: "Lackierung",
  //   src: "./assets/images/logo/Lackierung.png",
  //   preis: "ab 00€",
  //   showPreis: false,
  // },
];

export const kfzPagePriceData = {
  title: "Unsere Preise",
  icon: Icons.coinIcon,
  description:
    "Unsere Preise sind fair und transparent, denn wir möchten, dass Sie stets wissen, woran Sie sind. Die hier aufgeführte Preisliste dient als grobe Einschätzung der Kosten und bietet Ihnen eine Orientierungshilfe. Bitte beachten Sie, dass die endgültigen Preise von verschiedenen Faktoren abhängen, wie z. B. dem Fahrzeugtyp, dem Umfang der Reparatur und den benötigten Ersatzteilen. Natürlich ist jede Kostenschätzung unverbindlich, und wir besprechen alle Details mit Ihnen, bevor wir mit der Arbeit beginnen. So stellen wir sicher, dass keine Überraschungen auf Sie zukommen.",
  info: [
    {
      title: "**Bei umfangreicheren Reparaturen Preis auf Anfrage.",
    },
    {
      title: "**Verwendung von hochwertigen Ersatzteilen auf Wunsch möglich.",
    },
    {
      title:
        "**Zusätzliche Kosten können entstehen, wenn die Bedingungen nicht eingehalten werden. Bitte lesen Sie diese unbedingt vor der Reservierung!",
    },
  ],
};

export const kfzBedingungMainData = {
  title: "Bedingungen",
  icon: Icons.warning,
};
export const KFZBedingungen = [
  {
    title: "1. Auftragserteilung und Kostenvoranschlag",
    points: [
      "1.1. Reparaturen werden nur nach schriftlicher Auftragserteilung durchgeführt.",
      "1.2. Ein unverbindlicher Kostenvoranschlag kann erstellt werden. Kostenabweichungen von bis zu 10 % sind ohne Rücksprache zulässig.",
      "1.3. Zusätzliche Arbeiten werden nur nach vorheriger Zustimmung des Kunden durchgeführt.",
    ],
  },
  {
    title: "2. Haftung und Ersatzteile",
    points: [
      "2.1. Für eingebaute Ersatzteile gilt eine gesetzliche Gewährleistung, sofern nicht anders vereinbart.",
      "2.2. Die Haftung für mitgebrachte Ersatzteile des Kunden wird ausgeschlossen.",
      "2.3. Gebrauchte oder generalüberholte Ersatzteile werden nur nach ausdrücklicher Vereinbarung eingebaut.",
    ],
  },
  {
    title: "3. Abholung und Aufbewahrung",
    points: [
      "3.1. Das Fahrzeug muss innerhalb von 3 Werktagen nach Fertigstellung abgeholt werden. Andernfalls können Standgebühren erhoben werden.",
      "3.2. Die Werkstatt haftet nicht für persönliche Gegenstände, die im Fahrzeug belassen werden.",
    ],
  },
  {
    title: "4 Zahlungsbedingungen",
    points: [
      "4.1. Die Bezahlung erfolgt in der Regel bei Abholung des Fahrzeugs in bar, per EC- oder Kreditkarte.",
      "4.2. Rechnungen sind spätestens innerhalb von 7 Tagen nach Erhalt ohne Abzug zu zahlen.",
      "4.3. Bei Zahlungsverzug können Verzugszinsen und Mahngebühren erhoben werden.",
    ],
  },
  {
    title: "5 Reklamationen und Nachbesserungen",
    points: [
      "5.1. Beanstandungen sind unverzüglich, spätestens jedoch innerhalb von 14 Tagen nach Abholung, schriftlich mitzuteilen.",
      "5.2. Die Werkstatt hat das Recht, Mängel innerhalb einer angemessenen Frist selbst zu beheben.",
    ],
  },
  {
    title: "6 Sicherheits- und Umweltschutzmaßnahmen",
    points: [
      "6.1. Arbeiten am Fahrzeug dürfen ausschließlich durch autorisiertes Werkstattpersonal durchgeführt werden.",
      "6.2. Altteile und Verbrauchsmaterialien werden umweltgerecht entsorgt. Kosten für Entsorgung können separat berechnet werden.",
    ],
  },
  {
    title: "7 Testfahrten und Probefahrten",
    points: [
      "7.1. Die Werkstatt ist berechtigt, Testfahrten und Funktionsprüfungen durchzuführen.",
      "7.2. Eventuelle Schäden während der Probefahrten werden durch die Betriebshaftpflichtversicherung abgedeckt.",
    ],
  },
];
