import React from 'react';
import {
	FooterContainer,
	NavLinks,
} from './FooterStyles';
import { footerData, footerSocialData } from '../../data/FooterData';
import { ColumnContainer, Label, RowContainer, Space, LabelXXS, SafePanel, ImageSmall, LabelL } from '../Ui/Ui';
import { NavIcon, NavLogo } from '../NavBar/NavBarStyles';
import { useNavigate } from 'react-router-dom';
import { data } from "../../data/FooterData";
import { scrollToTop } from '../../utils/utilities';
import { srcData } from '../../data/SrcData';

function Footer() {
	let navigate = useNavigate();
	const navigation = (to,id) => {
		navigate(to,id);
		scrollToTop();
	  };
	return (
	<FooterContainer>
	<SafePanel normal={false} allowMargin={false}>
	<ColumnContainer centerAlign={true} style={{width:"100%"}}>
	<NavLogo to="/" onClick={scrollToTop} style={{margin:"0"}}>
    <NavIcon src={srcData.logo.localuri} alt="logo" />
    </NavLogo>
	<RowContainer style={{justifyContent:"space-between", gap:"1rem"}}>
	<ColumnContainer>
	<LabelL color='white' style={{fontWeight:"bolder"}}>LTM Transportservice GbR</LabelL>
	<Space style={{height:"1rem"}}/>
	<Label color='white'>Hinter den Kirschkaten 35</Label>
	<Label color='white'>23560 Lübeck</Label>
	</ColumnContainer>
	<ColumnContainer>
	<LabelL color='white' style={{fontWeight:"bolder"}}>Kontakt</LabelL>
	<Space color='white' style={{height:"1rem"}}/>
	<Label color='white'>015780456850</Label>
	<Label color='white'>info@ltm-hl.de</Label>
	</ColumnContainer>
	<ColumnContainer>
	<LabelL color='white' style={{fontWeight:"bolder"}}>Öffnungszeiten</LabelL>
	<Space style={{height:"1rem"}}/>
	<RowContainer sizing={false} style={{justifyContent:"space-between"}}>
	<Label color='white' style={{whiteSpace: "pre"}}>Mo. - Di.</Label>
	<Label color='white' style={{whiteSpace: "pre"}}>09:30 - 17:00</Label>
	</RowContainer>
	<RowContainer sizing={false} style={{justifyContent:"space-between"}}>
	<Label color='white' style={{whiteSpace: "pre"}}>Fr.</Label>
	<Label color='white' style={{whiteSpace: "pre"}}>09:30 - 16:00</Label>
	</RowContainer>
	<RowContainer sizing={false} style={{justifyContent:"space-between"}}>
	<Label color='white' style={{whiteSpace: "pre"}}>Sa. - So.</Label>
	<Label color='white' style={{whiteSpace: "pre"}}>geschloßen</Label>
	</RowContainer>
	</ColumnContainer>
	<ColumnContainer>
	<LabelL color='white' style={{fontWeight:"bolder"}}>Standort</LabelL>
	<Space color='white' style={{height:"1rem"}}/>
	<Label color='white'>Hinter den Kirchkaten 35</Label>
	<Label>(Eingang über die Henschelstraße)</Label>
	<ImageSmall src={srcData.location.localuri} style={{ marginBottom:"1rem"}} alt="location"/>
	</ColumnContainer>
	<ColumnContainer>
	<LabelL color='white' style={{fontWeight:"bolder"}}>Schnellzugriff</LabelL>
	<Space style={{height:"1rem"}}/>
	{data.map((el,index) => (
                <NavLinks onClick={() => navigation(el.to, el.id)} key={index}>
                  {el.text}
                </NavLinks>
            ))}
	</ColumnContainer>
	</RowContainer>
	<RowContainer style={{justifyContent:"center", marginTop:"2rem", alignContent:"center"}}>
	<ColumnContainer style={{flexDirection:"column-reverse"}}>
	<LabelXXS style={{ opacity:"0.1", margin:"0", color:"wheat"}}>by Adham Kamala</LabelXXS>
	<LabelXXS style={{ opacity:"0.1", margin:"0", color:"wheat"}}>designed and developed</LabelXXS>
	<LabelXXS style={{fontWeight:"bolder", opacity:"0.1", margin:"0", color:"wheat"}}>© 2025 LTM</LabelXXS>
	</ColumnContainer>
	</RowContainer>
	</ColumnContainer>
	</SafePanel>
	</FooterContainer>
	);
}

export default Footer;