import { Helmet } from "react-helmet-async";
import { ColumnContainer, Label, LimitedContainer, Panel, SafePanel, Space, Button, LabelXXL} from "../components/Ui/Ui";
import { NotFoundPageMetaData } from "../data/MetaData";
import { notFoundData } from "../data/NotFoundData";
import { NavToContactPage, scrollToTop } from "../utils/utilities";
import { useNavigate } from "react-router-dom";
import { dataByKey } from "../data/NavBarData";

const NotFound = () => {
  const navigate = useNavigate();
    return ( 
      <>
      <Helmet>
        <title>{NotFoundPageMetaData.title}</title>
        {NotFoundPageMetaData.meta.map((meta, index) => (
          <meta
            key={index}
            {...(meta.property
              ? { property: meta.property }
              : { name: meta.name })}
            content={meta.content}
          />
        ))}
        <link rel="canonical" href={NotFoundPageMetaData.canonical} />
        <script type="application/ld+json">
          {JSON.stringify(NotFoundPageMetaData.structuredData)}
        </script>
      </Helmet>
      <Panel style={{background:"white"}}>
      <SafePanel style={{}}>
          <ColumnContainer>
            <LimitedContainer >
              <LabelXXL
               color= "black" fontWeight= "900"
              >
                {notFoundData.name}
              </LabelXXL>
            </LimitedContainer>
            <LimitedContainer >
              <Label style={{ color: "black" }}>
                {notFoundData.description}
              </Label>
            </LimitedContainer>
            <Space style={{ height: "2rem" }} />
            <LimitedContainer >
              <Button onClick={() => {
                  NavToContactPage(navigate, dataByKey.startseite.to);
                  scrollToTop();
                }}> {notFoundData.btn}
              </Button>
            </LimitedContainer>
          </ColumnContainer>
        </SafePanel>
      </Panel>
      </>
     );
}
 
export default NotFound;