import { Helmet } from "react-helmet-async";
import {
  ColumnContainer,
  Label,
  Panel,
  SafePanel,
  LimitedContainer,
  StyledLink,
  LimitedContainerMedium,
  LabelXXL,
} from "../components/Ui/Ui";
import { creditsPageAnimationsData, creditsPageFontsData, creditsPageIconData, creditsPageImagesData, creditsPageMainData } from "../data/CreditsData";
import { CreditsPageMetaData } from "../data/MetaData";
const Credits = () => {
  return (
    <>
       <Helmet>
        <title>{CreditsPageMetaData.title}</title>
        {CreditsPageMetaData.meta.map((meta, index) => (
          <meta
            key={index}
            {...(meta.property ? { property: meta.property } : { name: meta.name })}
            content={meta.content}
          />
        ))}
        <link rel="canonical" href={CreditsPageMetaData.canonical} />
        <script type="application/ld+json">
          {JSON.stringify(CreditsPageMetaData.structuredData)}
        </script>
      </Helmet>
      <Panel style={{ background: "white" }} center={true}>
        <SafePanel  normal={false} allowMargin={false}>
          <ColumnContainer>
          <LimitedContainerMedium>
            <LabelXXL
            color="black" fontWeight="900"
            >
              {creditsPageMainData.title}
            </LabelXXL>
            
            </LimitedContainerMedium>
            <LimitedContainerMedium>
            <Label style={{ color: "black" }}>
            {creditsPageMainData.description}
            </Label>
            </LimitedContainerMedium>
          </ColumnContainer>
        </SafePanel>
      </Panel>
      <Panel style={{ background: "black" }}>
        <SafePanel>
          <ColumnContainer style={{ width: "100%" }}>
            <LabelXXL
            color="white" fontWeight="900"
            >
              {creditsPageIconData.title}
            </LabelXXL>
            <Label
              style={{ color: "white", fontWeight: "600", fontSize: "1.5rem" }}
            >
             <StyledLink href={creditsPageIconData.website}> {creditsPageIconData.name}</StyledLink>
            </Label>
            {creditsPageIconData.authors.map((element, index) => (
              <ColumnContainer>
                  <Label style={{color: "black", fontSize: "1rem", }} key={index}>
                  <StyledLink href={element.link}> {element.name}</StyledLink> 
                  </Label>
                  </ColumnContainer>
              ))}
          </ColumnContainer>
        </SafePanel>
      </Panel>
      {/* <Panel style={{ background: "red" }}>
        <SafePanel>
        <ColumnContainer style={{ width: "100%" }}>
            <Label
              style={{ color: "white", fontWeight: "900", fontSize: "3rem" }}
            >
              {creditsPageImagesData.title}
            </Label>
            <Label
              style={{ color: "white", fontWeight: "600", fontSize: "1.5rem" }}
            >
             <StyledLink href={creditsPageImagesData.website}> {creditsPageImagesData.name}</StyledLink>
            </Label>
            {creditsPageImagesData.authors.map((element, index) => (
              <ColumnContainer>
                  <Label style={{color: "black", fontSize: "1rem", }} key={index}>
                  <StyledLink href={element.link}> {element.name}</StyledLink> 
                  </Label>
                  </ColumnContainer>
              ))}
          </ColumnContainer>
        </SafePanel>
      </Panel>
      <Panel style={{ background: "white" }}>
        <SafePanel>
        <ColumnContainer style={{ width: "100%" }}>
            <Label
              style={{ color: "black", fontWeight: "900", fontSize: "3rem" }}
            >
              {creditsPageAnimationsData.title}
            </Label>
            <Label
              style={{ color: "black", fontWeight: "600", fontSize: "1.5rem" }}
            >
             <StyledLink href={creditsPageAnimationsData.website}> {creditsPageAnimationsData.name}</StyledLink>
            </Label>
            {creditsPageAnimationsData.authors.map((element, index) => (
              <ColumnContainer>
                  <Label style={{color: "black", fontSize: "1rem", }} key={index}>
                  <StyledLink style={{color: "black"}} href={element.link}> {element.name}</StyledLink> 
                  </Label>
                  </ColumnContainer>
              ))}
          </ColumnContainer>
        </SafePanel>
      </Panel>
      <Panel style={{ background: "black" }}>
        <SafePanel>
        <ColumnContainer style={{ width: "100%" }}>
            <Label
              style={{ color: "white", fontWeight: "900", fontSize: "3rem" }}
            >
              {creditsPageFontsData.title}
            </Label>
            <Label
              style={{ color: "white", fontWeight: "600", fontSize: "1.5rem" }}
            >
             <StyledLink href={creditsPageFontsData.website}> {creditsPageFontsData.name}</StyledLink>
            </Label>
            {creditsPageFontsData.authors.map((element, index) => (
              <ColumnContainer>
                  <Label style={{color: "white", fontSize: "1rem", }} key={index}>
                  <StyledLink href={element.link}> {element.name}</StyledLink> 
                  </Label>
                  </ColumnContainer>
              ))}
          </ColumnContainer>
        </SafePanel>
      </Panel> */}
    </>
  );
};

export default Credits;
