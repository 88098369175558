import { Icons } from "../components/Icons/Icons";
import { srcData } from "./SrcData";

export const homePageMainPanelData = {
  title: "Ob Mieten oder reparieren – Wir sind für Sie da!",
  description:
    "Unsere Firma bietet Ihnen einen umfassenden Service rund ums Fahrzeug – flexibel, schnell und zu günstigen Preisen. Bei uns können Sie Transporter unkompliziert und kostengünstig mieten, ideal für Umzüge oder Transporte. Unsere KFZ-Dienstleistungen umfassen Reparaturen, Inspektionen und TÜV-Abnahmen, alles effizient und zu fairen Konditionen. Darüber hinaus steht Ihnen unsere Selbsthilfewerkstatt zur Verfügung, ausgestattet mit allen notwendigen Werkzeugen und Geräten, damit Sie Ihr Fahrzeug eigenständig warten oder reparieren können – schnell, professionell und erschwinglich. Ihr Partner für Mobilität und Werkstattservice!",
  btn: "Termin Vereinbaren",
  marquee:"Selbsthilfewerkstatt jetzt verfügbar!"
};

export const homePageSecondryPanelData = {
  title: "Was wir anbieten",
  points: [
    {
      title: "Transportermieten",
      description:
        "Unsere beliebter Service bleibt weiterhin im angebot mit flexiblen mietzeiten und günstigere Preise.",
      to:"/rent",
      icon:Icons.transporter,
    },
    {
      title: "KFZ Werkstatt",
      description:
        "Wir reparieren auch Autos und bieten außerdem vollständige Inspektion und auch TÜV an",
        to:"/kfz",
        icon:Icons.kfz,
    },
    {
      title: "Selbsthilfewerkstatt",
      description:
        "Wenn sie das Know How haben können sie auch selber ihr auto reparieren, wir besorgen alle werkzeuge, hebbühnen und alles was sie brauchen",
        to:"/diy",
        icon:Icons.selfKfz,
    },
  ],
};

export const homePageThirdlyPanelData = {
  title: "Warum LTM?",
  points: [
    {
      name: "Günstigere Preise",
      description:
        "Unsere wettbewerbsfähigen Preise bieten Ihnen höchste Qualität zu einem fairen Preis.",
      icon:Icons.coinIcon,
    },
    {
      name: "Schnelle Antwortzeit",
      description:
        "Wir garantieren eine zügige Bearbeitung Ihrer Anfragen und minimieren Wartezeiten.",
        icon:Icons.fastWatch,
    },
    {
      name: "Schnelle Reparaturen",
      description:
        "Für einfache Arbeiten wie Ölwechsel oder Reifenwechsel ist Ihr Fahrzeug oft noch am selben Tag fertig. Lange Wartezeiten auf Termine gehören bei uns der Vergangenheit an.",
        icon:Icons.tools,
    },
    {
      name: "Flexibilität",
      description:
        "Unser Service passt sich Ihren individuellen Bedürfnissen und Zeitplänen an.",
        icon:Icons.flexible,
    },
    {
      name: "Hervorragender Kundenservice",
      description:
        "Wir stehen Ihnen mit unserer Erfahrung und Unterstützung jederzeit zur Verfügung.",
       icon:Icons.customerService,
    },
    {
      name: "Transparente Kommunikation",
      description:
        "Sie werden über alle Schritte klar und offen informiert, ohne versteckte Kosten.",
        icon:Icons.communication,
    },
    {
      name: "Zuverlässigkeit",
      description:
        "Sie können sich auf uns verlassen, dass wir Termine einhalten und eine ausgezeichnete Arbeit liefern.",
        icon:Icons.trust,
    },
    {
      name: "Individuelle Beratung",
      description:
        "Wir nehmen uns Zeit, um die beste Lösung für Ihre Bedürfnisse zu finden.",
        icon:Icons.advice,
    },
  ],
};
