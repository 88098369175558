import { Icons } from "../components/Icons/Icons";

export const selfKfzPageMainData = {
  title: "Weil Selbermachen einfach besser ist.",
  description:
    "Für alle Profis und diejenigen, die selbst Hand anlegen möchten, wir haben große Neuigkeiten für Sie: Ab jetzt steht Ihnen unsere Selbsthilfewerkstatt zur Verfügung! Sie bietet Ihnen die perfekte Möglichkeit, Ihr Fahrzeug eigenständig zu reparieren oder zu warten. Mit einer umfangreichen Ausstattung an professionellen Werkzeugen und großzügigen Arbeitsbereichen schaffen wir die ideale Umgebung für Ihre Arbeiten. Egal ob kleinere Wartungen wie Öl- und Reifenwechsel oder umfangreichere Reparaturen – bei uns finden Sie alles, was Sie brauchen, um Ihr Projekt erfolgreich umzusetzen. Hier haben Sie die Freiheit, Ihre Arbeiten in Ihrem eigenen Tempo durchzuführen, unterstützt durch hochwertige Geräte und eine Werkstatt, die für verschiedenste Anforderungen ausgelegt ist.",
    btn:"Jetzt Reservieren"
  };

  export const selfKfzPageSecondData = {
    title: "Die Werkstatt",
    icon:Icons.selfKfz,
    infos:[
      {
        title:"Umfassende Ausstattung mit vielfältigen Werkzeugen und Maschinen.",
        description:"Unsere Werkstatt bietet eine umfassende Ausstattung mit allem, was Sie für Ihre Arbeiten benötigen. Dazu gehören Hebebühnen, eine Auswuchtmaschine, eine Schweißmaschine, eine Pressmaschine und gut ausgestattete Werkzeugwagen. Wir stellen sicher, dass Sie alles zur Verfügung haben, um Ihre Reparaturen und Wartungsarbeiten problemlos zu erledigen.",
        icon:"",
      },
      {
        title:"Betreuung oder Unterstützung auf Anfrage verfügbar.",
        description:"Auf Anfrage bieten wir Ihnen Unterstützung und Betreuung während Ihrer Arbeiten an. Unser Team steht Ihnen zur Seite, um sicherzustellen, dass Ihre Reparaturen oder Wartungsarbeiten reibungslos verlaufen.",
        icon:"",
      },
      {
        title:"Großzügiger Parkplatz direkt vor Ort",
        description:"Bei uns finden Sie ausreichend Platz direkt auf dem Hof. Unser großzügiger Parkplatz ermöglicht Ihnen ein bequemes Parken und schnellen Zugang zu unserer Werkstatt.",
        icon:"",
      }
    ]
    };
  export const SelbstHilfeWerkstattBedingungen = [
  {
    title: "1. Allgemeine Nutzung",
    points: [
      "1.1 Pro Reservierung dürfen maximal zwei Personen die Werkstatt gleichzeitig nutzen. Ausnahmen bedürfen der schriftlichen Zustimmung des Betreibers.",
      "1.2 Die Nutzung der Werkstatt erfolgt ausschließlich nach vorheriger Reservierung und Bestätigung durch den Betreiber.",
      "1.3 Die Nutzung der Werkstatt und der bereitgestellten Werkzeuge erfolgt auf eigene Gefahr. Vor Beginn der Arbeiten ist eine schriftliche Haftungsausschlusserklärung zu unterzeichnen.",
    ],
  },
  {
    title: "2. Ordnung und Sauberkeit",
    points: [
      "2.1 Alle genutzten Arbeitsplätze, Werkzeuge und Maschinen müssen sauber und unbeschädigt zurückgegeben werden.",
      "2.2 Fehlende oder beschädigte Werkzeuge und Maschinen werden dem Nutzer in Rechnung gestellt.",
      "2.3 Bei nicht ordnungsgemäßer Reinigung wird eine Reinigungsgebühr von mindestens 50 € erhoben.",
    ],
  },
  {
    title: "3 Betreuung und Zusatzkosten",
    points: [
      "3.1 Auf Wunsch steht ein Werkstattbetreuer zur Unterstützung bereit. Diese Leistung kann mit zusätzlichen Kosten verbunden sein, die im Voraus zu vereinbaren sind.",
      "3.2 Verbrauchsmaterialien (z. B. Schmierstoffe, Reinigungsmittel) werden separat berechnet.",
    ],
  },
  {
    title: "4 Sicherheitsvorschriften",
    points: [
      "4.1 Die geltenden Sicherheitsvorschriften sind jederzeit zu beachten. Dazu gehören insbesondere: Tragen von geeigneter Schutzkleidung (z. B. Sicherheitsschuhe, Handschuhe). Ordnungsgemäßer Umgang mit Maschinen und Werkzeugen gemäß den Bedienungsanleitungen.",
      "4.2 Arbeiten, die eine besondere Gefährdung darstellen oder spezielle Fachkenntnisse erfordern (z. B. Arbeiten an Hochvolt-Systemen von Elektrofahrzeugen), sind untersagt.",
    ],
  },
  {
    title: "5 Haftung und Versicherung",
    points: [
      "5.1 Der Betreiber haftet nicht für Personen- oder Sachschäden, die durch unsachgemäße Nutzung der Werkstatt oder der Werkzeuge entstehen.",
      "5.2 Nutzer haften für Schäden an der Werkstatt, deren Ausstattung und Dritte im Rahmen ihrer Nutzung. Der Betreiber empfiehlt den Abschluss einer privaten Haftpflichtversicherung.",
    ],
  },
  {
    title: "6 Verhaltensregeln",
    points: [
      "6.1 Das Arbeiten unter Einfluss von Alkohol, Drogen oder anderen bewusstseinsverändernden Substanzen ist strengstens untersagt.",
      "6.2 Das Rauchen sowie die Nutzung von offenem Feuer sind innerhalb der Werkstatt untersagt.",
    ],
  },
  {
    title: "7 Nutzungseinschränkungen",
    points: [
      "7.1 Die Werkstatt darf nur während der vereinbarten Nutzungszeiten betreten und genutzt werden. Ein längeres Verweilen außerhalb der Zeiten ist nicht gestattet.",
      "7.2 Die Weitergabe von Zugangsberechtigungen an Dritte ist nicht gestattet.",
    ],
  },
  {
    title: "8 Sonstiges",
    points: [
      "8.1 Bei Streitigkeiten zwischen Nutzer und Betreiber gilt deutsches Recht.",
      "8.2 Der Betreiber behält sich das Recht vor, diese Bedingungen jederzeit zu ändern. Änderungen werden vor Inkrafttreten bekannt gegeben.",
    ],
  },
];

export const selfKFZPageImageList = [
  './assets/KFZ/1.jpeg',
  './assets/KFZ/3.jpeg',
  './assets/KFZ/5.jpeg',
  './assets/KFZ/6.jpeg',
  './assets/KFZ/7.jpeg',
  './assets/KFZ/8.jpeg',
];

export const selfKfzPageCarPriceData = {
  title: "Unsere Preise",
  icon:Icons.coinIcon,
  description:"Unsere Preise sind fair und erschwinglich, sodass auch diejenigen, die sich nicht so gut mit Autos auskennen, die Möglichkeit haben, ihr Fahrzeug selbst zu reparieren. Wir bieten eine kostengünstige Alternative, um Reparaturen und Wartungen eigenständig durchzuführen.",
  wochentariftitle: "Wochentarif",
  wochentarif: "Montag bis Freitag",
  wochenendtariftitle: "Wochenendtarif",
  wochenendtarif: "Samstung und Sonntag",
  standNo:"Stand 01.2025",
  standEn:"Stand 01.2025",
  info:[
    {
      title:"**Es können zusätzliche Kosten anfallen, wenn die Bedingungen nicht eingehalten werden. Bitte lesen Sie diese unbedingt vor der Reservierung!",
    },
  ],
  wochenTarif: [
    {
      description: "Hebebühne",
      art: "pro Stunde",
      price: "25€",
    },
    {
      description: "Werkzeug",
      art: "Einmalig",
      price: "25€",
    },
    {
      description: "Handschuhe",
      art: "pro Paar",
      price: "3€",
    },
    {
      description: "Bremsreiniger",
      art: "pro Flasche",
      price: "7€",
    },
    {
      description: "Unterbodenschutz",
      art: "pro Flasche",
      price: "9€",
    },
    {
      description: "Bremsflüssigkeit",
      art: "pro Liter",
      price: "10€",
    },
  ],
  wochenEndtarif: [
    {
      description: "Hebebühne",
      art: "pro Stunde",
      price: "30€",
    },
    {
      description: "Werkzeug",
      art: "Einmalig",
      price: "30€",
    },
    {
      description: "Handschuhe",
      art: "pro Paar",
      price: "4€",
    },
    {
      description: "Bremsreiniger",
      art: "pro Flasche",
      price: "8€",
    },
    {
      description: "Unterbodenschutz",
      art: "pro Flasche",
      price: "10€",
    },
    {
      description: "Bremsflüssigkeit",
      art: "pro Liter",
      price: "11€",
    },
  ],
};

export const selfKfzBedingungPageData = {
  title: "Bedingungen",
  icon:Icons.warning,
};