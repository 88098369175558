export const data = [
  {
    to: "/",
    //text: "Startseite",
    id: "startseite",
  },
  {
    to: "/rent",
    text: "Mieten",
  },
  {
    to: "/kfz",
    text: "KFZ",
  },
  {
    to: "/diy",
    text: "Selbsthilfewerkstatt",
  },
  // {
  //   to: "/delivery",
  //   text: "Lieferung",
  // },
  // {
  //   to: "/offer",
  //   text: "Angebote",
  // },
  {
    to: "/contact",
    text: "Kontakt",
  },
  {
    to: "/aboutus",
    text: "Über uns",
  },
];

export const dataByKey = {
  rent: { to: "/rent"},
  startseite: {to: "/"},
  kfz: { to: "/kfz"},
  diy: { to: "/diy"},
  contact: { to: "/contact"},
  aboutus: { to: "/aboutus"},
};