import { Helmet } from "react-helmet-async";
import {
  KFZBedingungen,
  kfzBedingungMainData,
  KFZLeistungen,
  KfzPageMainData,
  kfzPagePriceData,
  KfzPageWerkstattData,
} from "../data/KFZPageData";
import {
  ColumnContainer,
  Label,
  LimitedContainer,
  Panel,
  SafePanel,
  Space,
  Button,
  ScrollContainer,
  RowContainer,
  ScrollContainerSize,
  GridItem,
  LimitedContainerLarge,
  LimitedContainerMedium,
  LabelXXL,
  LabelS,
  ResponsiveIcon,
  LabelL,
  PriceTable,
  TableRow,
  TableCell,
} from "../components/Ui/Ui";
import { NavToContactPage, scrollToTop } from "../utils/utilities";
import { dataByKey } from "../data/NavBarData";
import { useNavigate } from "react-router-dom";
import { KfzPageMetaData } from "../data/MetaData";
const KFZ = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>{KfzPageMetaData.title}</title>
        {KfzPageMetaData.meta.map((meta, index) => (
          <meta
            key={index}
            {...(meta.property
              ? { property: meta.property }
              : { name: meta.name })}
            content={meta.content}
          />
        ))}
        <link rel="canonical" href={KfzPageMetaData.canonical} />
        <script type="application/ld+json">
          {JSON.stringify(KfzPageMetaData.structuredData)}
        </script>
      </Helmet>
      <Panel style={{ background: "white" }}>
        <SafePanel normal={false} allowMargin={false}>
          <ColumnContainer>
            <LimitedContainerMedium>
              <LabelXXL color="black" fontWeight="900">
                {KfzPageMainData.title}
              </LabelXXL>
            </LimitedContainerMedium>
            <LimitedContainer>
              <Label style={{ color: "black" }}>
                {KfzPageMainData.description}
              </Label>
            </LimitedContainer>
            <Space style={{ height: "2rem" }} />
            <LimitedContainer>
              <Button
                onClick={() => {
                  NavToContactPage(navigate, dataByKey.contact.to);
                  scrollToTop();
                }}
              >
                Jetzt Reservieren
              </Button>
            </LimitedContainer>
          </ColumnContainer>
        </SafePanel>
      </Panel>
      <Panel style={{ background: "black" }}>
        <SafePanel>
          <ColumnContainer style={{ width: "100%" }}>
            <RowContainer sizing={false}>
              <LabelXXL color="white" fontWeight="900">
                {KfzPageWerkstattData.title}
              </LabelXXL>
              <Space style={{ width: "0.5rem" }} />

              {(() => {
                const Icon = KfzPageWerkstattData.icon;
                return (
                  <ResponsiveIcon size="4rem">
                    <Icon
                      fill="white"
                      viewBox="-2 -2 28 28"
                      style={{
                        alignSelf: "center",
                        strokeWidth: "1",
                        stroke: "white",
                      }}
                    />
                  </ResponsiveIcon>
                );
              })()}
            </RowContainer>
            <ColumnContainer
              style={{ height: "100%", justifyContent: "center" }}
            >
              <ScrollContainerSize>
                {KFZLeistungen.map((element, index) => (
                  <LabelL fontWeight="600" style={{ color: "white" }} key={index}>
                    {element.name}
                  </LabelL>
                ))}
              </ScrollContainerSize>
            </ColumnContainer>
          </ColumnContainer>
        </SafePanel>
      </Panel>
      <Panel style={{ background: "red" }}>
        <SafePanel>
          <ColumnContainer>
            <RowContainer sizing={false}>
              <LabelXXL color="white" fontWeight="900">
                {kfzPagePriceData.title}
              </LabelXXL>
              <Space style={{ width: "0.5rem" }} />

              {(() => {
                const Icon = kfzPagePriceData.icon;
                return (
                  <ResponsiveIcon size="4rem">
                    <Icon
                      fill="white"
                      viewBox="-2 -2 28 28"
                      style={{
                        alignSelf: "center",
                        strokeWidth: "1",
                        stroke: "white",
                      }}
                    />
                  </ResponsiveIcon>
                );
              })()}
            </RowContainer>
            <Space style={{ height: "3rem" }} />
            <LimitedContainerLarge>
              <Label color="white">{kfzPagePriceData.description}</Label>
            </LimitedContainerLarge>
            <Space style={{ height: "2rem" }} />
            {/* <ScrollContainerSize>
              {KFZLeistungen.map((element, index) => {
                if (!element.showPreis) return null;
                return (
                  <RowContainer>
                    <Label color="white" key={index}>
                      {element.name}
                    </Label>
                    <Space style={{ width: "1rem" }} />
                    <Label color="white" key={index}>
                      {element.preis}
                    </Label>
                  </RowContainer>
                );
              })}
            </ScrollContainerSize> */}
           
            <PriceTable>
              {KFZLeistungen.map((element, index) => {
                if (!element.showPreis) return null;
                return (
                  <TableRow key={index}>
                    <TableCell><Label color="black" fontWeight="600" key={index}>
                      {element.name}
                    </Label></TableCell>
                    <TableCell>  <Label color="black" fontWeight="600"  key={index}>
                      {element.preis}
                    </Label></TableCell>
                  </TableRow>
                );
              })}
            </PriceTable>
            <RowContainer style={{flexDirection:"row-reverse"}}><LabelS
                color="white"
                fontWeight="600"
                style={{
                  opacity: "0.3",
                  marginRight:"0.5rem"
                }}
              >
                {KfzPageWerkstattData.stand}
              </LabelS></RowContainer>
           
            <Space style={{ height: "2rem" }} />
            {kfzPagePriceData.info.map((element, index) => (
              <LabelS
                color="white"
                fontWeight="600"
                style={{
                  opacity: "0.3",
                }}
                key={index}
              >
                {element.title}
              </LabelS>
            ))}
            <Space style={{ height: "12rem" }} />
            <RowContainer sizing={false}>
              <LabelXXL color="white" fontWeight="900">
                {kfzBedingungMainData.title}
              </LabelXXL>
              <Space style={{ width: "0.5rem" }} />

              {(() => {
                const Icon = kfzBedingungMainData.icon;
                return (
                  <ResponsiveIcon size="4rem">
                    <Icon
                      fill="white"
                      viewBox="-2 -2 28 28"
                      style={{
                        alignSelf: "center",
                        strokeWidth: "1",
                        stroke: "white",
                      }}
                    />
                  </ResponsiveIcon>
                );
              })()}
            </RowContainer>
            <Space style={{ height: "3rem" }} />
            <ScrollContainer>
              {KFZBedingungen.map((section, index) => (
                <ColumnContainer key={index}>
                  <LabelL key={index} color="white" fontWeight="900">
                    {section.title}
                  </LabelL>
                  {section.points.map((point, idx) => (
                    <Label key={idx} color="white">
                      {point}
                    </Label>
                  ))}
                  <Space style={{height:"1rem"}}/>
                </ColumnContainer>
              ))}
            </ScrollContainer>
          </ColumnContainer>
        </SafePanel>
      </Panel>
    </>
  );
};

export default KFZ;
