import { ReactComponent as CoinIcon } from '../../assets/icons/coins.svg';
import { ReactComponent as Advice } from '../../assets/icons/advice.svg';
import { ReactComponent as Communication } from '../../assets/icons/communication.svg';
import { ReactComponent as CustomerService } from '../../assets/icons/customerservice.svg';
import { ReactComponent as FastWatch } from '../../assets/icons/fastwatch.svg';
import { ReactComponent as Tools } from '../../assets/icons/tools.svg';
import { ReactComponent as Trust } from '../../assets/icons/trust.svg';
import { ReactComponent as Flexible } from '../../assets/icons/flexible.svg';
import { ReactComponent as Kfz } from '../../assets/icons/kfz.svg';
import { ReactComponent as Transporter } from '../../assets/icons/transporter.svg';
import { ReactComponent as SelfKfz } from '../../assets/icons/selfkfz.svg';
import { ReactComponent as Warning } from '../../assets/icons/warning.svg';
import { ReactComponent as Email } from '../../assets/icons/email.svg';
import { ReactComponent as Location } from '../../assets/icons/location.svg';
import { ReactComponent as Call } from '../../assets/icons/call.svg';

export const Icons = {
    coinIcon: CoinIcon,
    communication:Communication,
    customerService:CustomerService,
    fastWatch:FastWatch,
    tools:Tools,
    trust:Trust,
    advice:Advice,
    flexible:Flexible,
    kfz:Kfz,
    transporter:Transporter,
    selfKfz:SelfKfz,
    warning:Warning,
    email:Email,
    location:Location,
    call:Call,
  };