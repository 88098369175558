import { Helmet } from "react-helmet-async";
import {
  ColumnContainer,
  Label,
  Panel,
  SafePanel,
  Image,
  Space,
  Input,
  LimitedContainer,
  Button,
  BigInput,
  RowContainer,
  LimitedContainerLarge,
  LimitedContainerMedium,
  LabelXXL,
  ResponsiveIcon,
  LabelL,
  GlowingAnimationComp,
} from "../components/Ui/Ui";
import {
  contactPageKontaktFormData,
  contactPageMainData,
  contactPageSecondData,
} from "../data/ContactPageData";
import { ContactPageMetaData } from "../data/MetaData";
import { srcData } from "../data/SrcData";
const Contact = () => {
  return (
    <>
      <Helmet>
        <title>{ContactPageMetaData.title}</title>
        {ContactPageMetaData.meta.map((meta, index) => (
          <meta
            key={index}
            {...(meta.property
              ? { property: meta.property }
              : { name: meta.name })}
            content={meta.content}
          />
        ))}
        <link rel="canonical" href={ContactPageMetaData.canonical} />
        <script type="application/ld+json">
          {JSON.stringify(ContactPageMetaData.structuredData)}
        </script>
      </Helmet>
      <Panel style={{ background: "white" }}>
        <SafePanel normal={false} allowMargin={false}>
          <ColumnContainer>
            <LimitedContainerMedium >
              <LabelXXL
              color= "black" fontWeight="900"
              >
                {contactPageMainData.title}
              </LabelXXL>
            </LimitedContainerMedium>
            <LimitedContainerMedium>
              <Label style={{ color: "black" }}>
                {contactPageMainData.description}
              </Label>
            </LimitedContainerMedium>
            <Space style={{height:"2rem"}}/>
            <ColumnContainer style={{gap:"1rem"}}>
            {contactPageMainData.info.map((point, index) => {
              const Icon = point.icon;
              return (
                <RowContainer key={index} sizing={false}>
                  <ColumnContainer style={{ justifyContent: "center" }}>
                  <ResponsiveIcon size="3rem">
                    <Icon
                      fill="black"
                      viewBox="-2 -2 28 28" style={{alignSelf:"center", strokeWidth:"1", stroke:"black" }}  
                    />
                        </ResponsiveIcon>
                  </ColumnContainer>
                  <Space style={{ width: "1rem" }} />
                  <ColumnContainer  style={{ justifyContent: "center" }}>
                    <LabelL
                       color= "black"
                       fontWeight= "900"
                    >
                      {point.name}
                    </LabelL>
                   
                      <Label style={{ color: "black" }}>
                        {point.description}
                      </Label>
                  
                  </ColumnContainer>
                </RowContainer>
              );
            })}
            </ColumnContainer>
          </ColumnContainer>
        </SafePanel>
      </Panel>
      <Panel style={{ background: "black" }}>
        <SafePanel>
          <ColumnContainer style={{ width: "100%" }}>
            <LabelXXL
            color= "white" fontWeight="900"
            >
              {contactPageSecondData.title}
            </LabelXXL>
            <ColumnContainer
              style={{ height: "100%", justifyContent: "center" }}
            >
              <Image
                src={srcData.location.localuri}
                // style={{
                //   width: "30rem",
                //   borderRadius: "1rem",
                //   alignSelf: "center",
                  
                // }}
                alt="location"
              />
            </ColumnContainer>
          </ColumnContainer>
        </SafePanel>
      </Panel>
      <Panel style={{ background: "red" }}>
        <SafePanel>
          <ColumnContainer style={{ width: "100%" }}>
            <LabelXXL
             color= "white" fontWeight= "900"
            >
              {contactPageKontaktFormData.title}
            </LabelXXL>
            <ColumnContainer
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
          
             <Label
              style={{ color: "white", fontWeight: "900", fontSize: "2.5rem" }}
            >
              {contactPageKontaktFormData.ready}
            </Label>
           
              {/* <LimitedContainer
                style={{
                  width: "40vw",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Label>{contactPageKontaktFormData.name}</Label>
                <Input></Input>
                <Label>{contactPageKontaktFormData.email}</Label>
                <Input></Input>
                <Label>{contactPageKontaktFormData.msg}</Label>
                <BigInput></BigInput>
                <Space style={{ height: "2rem" }} />
                <Label style={{ opacity: "0.6" }}>
                  {contactPageKontaktFormData.tip}
                </Label>
                <LimitedContainer
                  style={{ width: "40vw", flexDirection: "row-reverse" }}
                >
                  <Button>{contactPageKontaktFormData.btn}</Button>
                </LimitedContainer>
              </LimitedContainer> */}
            </ColumnContainer>
          </ColumnContainer>
        </SafePanel>
      </Panel>
    </>
  );
};

export default Contact;
