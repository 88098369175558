import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga";
import { HelmetProvider } from 'react-helmet-async';

import Navbar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import GlobalStyle from "./globalStyles";
import Rent from "./pages/Rent";
import KFZ from "./pages/KFZ";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Delivery from "./pages/Delivery";
import SelfKfz from "./pages/SelfKfz";
import NotFound from "./pages/NotFound";
import AboutUs from "./pages/AboutUs";
import Credits from "./pages/Credits";

//const TRACKING_ID = "G-2QCJXTNZJ7"; // OUR_TRACKING_ID
const TRACKING_ID = "G-Q266L649DG";
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <HelmetProvider>
    <Router>
    <GlobalStyle/>
      <Navbar />
      <Routes>
      <Route path="/rent" element={<Rent />} />
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/rent" element={<Rent />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/diy" element={<SelfKfz />} />
        <Route path="/kfz" element={<KFZ />} />
        <Route path="/credits" element={<Credits />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
    </HelmetProvider>
  );
}

export default App;
