const LOCAL_URI = "./assets/";
const GLOBAL_URI = "https://www.example.com/assets/";
export const srcData = {
  logo: {
    localuri: `${LOCAL_URI}images/logo/47fcebf5fa98f74a46b161cf20eb8b0b.png`,
    globaluri: `${GLOBAL_URI}images/logo/47fcebf5fa98f74a46b161cf20eb8b0b.png`,
    alt: "LTM Transport und Autoservice",
  },
  location: {
    localuri: `${LOCAL_URI}images/location/6036cb33152ff96767202a6c20facb4b.png`,
    globaluri: `${GLOBAL_URI}images/location/6036cb33152ff96767202a6c20facb4b.png`,
    alt: "LTM Standort",
  },
  transporter1: {
    localuri: `${LOCAL_URI}images/transporter/49c0fe78d4178411b46c015f89f9daf1.jpeg`,
    globaluri: `${GLOBAL_URI}images/transporter/49c0fe78d4178411b46c015f89f9daf1.jpeg`,
    alt: "LTM Transporter",
  },
  kfz1: {
    localuri: `${LOCAL_URI}images/kfz/35dcb5c83066cf71a021452ae0697f1a.jpeg`,
    globaluri: `${GLOBAL_URI}images/kfz/35dcb5c83066cf71a021452ae0697f1a.jpeg`,
    alt: "LTM KFZ",
  },
  euroIcon: {
    localuri: `${LOCAL_URI}icons/svg/euro.svg`,
    globaluri: `${GLOBAL_URI}icons/svg/euro.svg`,
    alt: "LTM KFZ",
  },
  
};
