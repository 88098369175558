export const aboutUsPageMainData = {
  title: "Wie alles angefangen hat.",
  zustand:"Aktueller Zustand",
  goal:"Unsere Ziele",

  history: [
    {
      title: "Die Idee",
      description:
        "Im Corona-Lockdown als spontane Idee entstanden, gründeten wir 2020 unser Unternehmen mit einem einzigen Transporter direkt vor unserer Haustür in Lübeck. Es war zunächst mehr ein „drittes Standbein“, um dem plötzlich steigenden Bedarf an Transportmöglichkeiten aufgrund der außergewöhnlichen Situation gerecht zu werden. Wir wussten damals nicht, ob es sich wirklich etablieren würde – der erste Transporter war ein silberner Ford Transit L2H1 von 2008, und es war nur ein Versuch. Doch die Nachfrage war sofort riesig, und dank dieser hohen Nachfrage konnten wir bereits nach kurzer Zeit unser Angebot erweitern. Mit diesem ersten Transporter und der stetig wachsenden Kundenzahl konnten wir uns einen weiteren Ford Transit leisten, und nach und nach kamen auch mehr Fahrzeuge hinzu.",
      icon: "",
    },
    {
      title: "Nicht nur Transporter vermietung...",
      description:
        "Bis 2023 konzentrierten wir uns hauptsächlich auf die Vermietung von Transportern und kümmerten uns selbst um sämtliche Wartungs- und Inspektionsarbeiten. Der Geschäftsführer, der über umfassendes Wissen in der Fahrzeugreparatur verfügt, hat von Anfang an die Werkstättenbesuche für unsere Fahrzeuge vermieden und somit erhebliche Kosten eingespart. Doch dann kam eine neue Idee auf: Warum nur unsere eigenen Transporter reparieren, wenn wir auch anderen Menschen helfen könnten, ihre Autos in Top-Zustand zu halten? Mit dieser Überlegung starteten wir den nächsten großen Schritt in der Unternehmensentwicklung – die Eröffnung unserer eigenen Werkstatt. Dieser Schritt markiert einen wichtigen Meilenstein in unserer Geschichte und ermöglicht es uns, nicht nur unseren eigenen Fuhrpark zu betreuen, sondern auch anderen Fahrzeugbesitzern eine zuverlässige und kostengünstige Lösung für Reparaturen und Wartungen anzubieten.",
      icon: "",
    },
  ],
  nextStep: [
    {
      title: "Der Nächster Schirt..",
      description:
        "Ende 2024 hatten wir eine weitere, spannende Idee: Warum nicht auch all den Auto-Enthusiasten, Profis und denen, die es einfach mal selbst ausprobieren möchten, die Möglichkeit bieten, ihre eigenen Reparaturen und Wartungen durchzuführen? Mit dieser Überlegung haben wir die Selbsthilfewerkstatt ins Leben gerufen – eine innovative Lösung für alle, die ihre Fahrzeuge eigenständig pflegen möchten, aber die nötige Ausstattung und Expertise dafür nicht besitzen. Wir sind davon überzeugt, dass es auch in Lübeck eine große Nachfrage nach dieser Art von Service gibt, und deshalb starten wir unser Selbsthilfeprojekt zum 01.01.2025. In unserer Werkstatt können Sie fortan auf eine breite Palette an professionellen Werkzeugen und Maschinen zugreifen und dabei Ihre Reparaturen oder Wartungen in eigener Regie durchführen. Wir stellen Ihnen alles zur Verfügung, was Sie brauchen, um Ihr Fahrzeug effizient und sicher zu betreuen. Mit dieser Erweiterung bieten wir nicht nur eine Lösung für Profis und Hobbymechaniker, sondern auch für all diejenigen, die sich für das Thema Auto-Reparatur interessieren und es mal selbst ausprobieren möchten. Wir freuen uns darauf, Ihnen diese neue Möglichkeit zu eröffnen und Sie auf Ihrem Weg zu unterstützen!",
    },
  ],

  goals: [
    {
      title: "Zeitlose Ziele",
      description:
        "Unsere Ziele sind seit jeher zeitlos und orientieren sich an den Bedürfnissen unserer Kunden. Bereits 2020 war es uns wichtig, unseren Kunden nicht nur Transporter zu bieten, sondern auch einen umfassenden Service, der durch Kundenzufriedenheit, Flexibilität und sichere Fahrzeuge geprägt ist. Wir streben stets danach, unseren Kunden günstige Preise anzubieten und eine sofortige Antwort zu liefern, sei es bei der Vermietung eines Transporters oder bei Reparaturanfragen. Unsere Flexibilität zeigt sich darin, dass unsere Kunden nicht nur am selben Tag, sondern oft auch innerhalb einer Stunde, je nach Verfügbarkeit, ein Fahrzeug mieten können – mit von uns angebotenen Tarifen, die für jeden Kunden individuell angepasst sind. Diese Ziele waren von Anfang an entscheidend für unseren Erfolg, und wir haben damals viel Wert auf eine hohe Qualität und schnelle, zuverlässige Dienstleistungen gelegt. Auch heute noch setzen wir diese Werte an oberste Stelle. Unsere Mission ist es, unseren Kunden als zuverlässiger Partner zur Seite zu stehen – sei es bei der Miete eines Transporters, der Reparatur oder Wartung ihres Fahrzeugs oder durch unsere umfassende Selbsthilfewerkstatt. Wir wollen unseren Kunden nicht nur eine Lösung bieten, sondern die beste Lösung, die sich nach ihren individuellen Bedürfnissen richtet.",
    },
  ],
};
