import Marquee from "react-fast-marquee";
import styled, { keyframes } from "styled-components";

// Button
export const Button = styled.button`
  background-color: ${({ bgColor }) =>
    bgColor || "#000000"}; /* Black background */
  color: ${({ color }) => color || "#ffffff"}; /* White text */
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.2s;

  &:hover {
    background-color: ${({ hoverColor }) =>
      hoverColor || "#333333"}; /* Darker shade for hover */
  }

  &:active {
    opacity: 0.8; /* Opacity effect when pressed */
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  @media screen and (max-width: 1100px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.7rem;
  }
`;

// Input
export const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

// Card
export const Card = styled.div`
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
`;

// Divider
export const Divider = styled.hr`
  border: none;
  border-top: 1px solid #eee;
  margin: 1rem 0;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allows wrapping to a new row if needed */

  ${(props) =>
    props.sizing !== false &&
    `
    @media screen and (max-width: 960px) {
      flex-direction: column; /* Switch to column layout for smaller screens */
    }
  `}
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) =>
  props.centerAlign !== undefined &&
  props.centerAlign !== null &&
  props.centerAlign !== false &&
  `
    @media screen and (max-width: 960px) {
         align-items: center;
    }
  `}

`;

export const Space = styled.div`
  width: 1rem;
  display: inline-block;
`;

export const Panel = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) =>
    props.center !== false &&
    `
  justify-content: center; 
  }
  `}
`;
export const SafePanel = styled.div`
  width: 69%;
 
  display: flex;
  @media screen and (max-width: 960px) {
    width: 95%;
  }
  ${(props) =>
    props.normal !== false &&
    `
   min-height: 100vh;
  }
  `}
    ${(props) =>
    props.allowMargin !== false &&
    `
   margin-top: 6rem;
  margin-bottom: 2rem;
  }
  `}
`;

export const ContainerButton = styled.div`
  width: 25rem;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white; /* White background */
  color: black; /* Black text */
  border-radius: 1.5rem;
  cursor: pointer;
  transition: box-shadow 0.3s ease; /* Smooth transition for glow effect */

  position: relative;

  &:hover {
    box-shadow: 0 0 25px 1px white; /* Golden glow effect */
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    box-shadow: none; /* Removes shadow when disabled */
  }

  & > * {
    pointer-events: none; /* Prevent child elements from blocking events */
  }

  @media screen and (max-width: 1100px) {
    width: 22rem;
    height: 12rem;
  }

  @media screen and (max-width: 480px) {
    width: 18rem;
    height: 8rem;
  }
`;

export const BigInput = styled.textarea`
  width: 100%;
  height: 8rem; /* Höhe für längere Nachrichten */
  padding: 0.5rem;
  font-size: 1rem;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; /* Erlaubt Größenänderung in der Höhe */
  outline: none;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  }

  &::placeholder {
    color: #aaa;
  }
`;

export const ScrollContainer = styled.div`
  width: 100%; /* Adjust width as needed */
  height: 400px; /* Adjust height as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Prevents horizontal scrolling */

  &::-webkit-scrollbar {
    width: 8px; /* Scrollbar width */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc; /* Scrollbar thumb color */
    border-radius: 10px; /* Rounded scrollbar thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #aaaaaa; /* Hover effect on scrollbar thumb */
  }
`;

export const StyledLink = styled.a`
  color: white;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const SvgImg = styled.svg`
  width: ${({ size }) => size || "24px"};
  height: ${({ size }) => size || "24px"};
  fill: ${({ color }) => color || "currentColor"};
  stroke: ${({ strokeColor }) => strokeColor || "none"};
  transition: all 0.3s ease;

  &:hover {
    fill: ${({ hoverColor }) => hoverColor || hoverColor || "currentColor"};
  }
`;

export const ScrollContainerSize = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(200px, 1fr)
  ); /* Auto-fit adjusts the columns */
  row-gap: 1.5rem;
  column-gap: 7rem;
  width: 100%;
  padding: 16px;
  overflow-y: auto; /* Scrollable container */
`;
export const GridItem = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
`;

export const LabelXXL = styled.label`
  color: ${(props) => props.color || "black"};
  font-weight: ${(props) => props.fontWeight || "normal"};
  font-size: 3rem;
  @media screen and (max-width: 1100px) {
    font-size: 2.7rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 2.4rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 2.1rem;
  }
`;
export const LabelXL = styled.label`
  color: ${(props) => props.color || "black"};
  font-weight: ${(props) => props.fontWeight || "normal"};
  font-size: 2rem;
  @media screen and (max-width: 1100px) {
    font-size: 1.8rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.6rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.4rem;
  }
`;
export const LabelL = styled.label`
  color: ${(props) => props.color || "black"};
  font-weight: ${(props) => props.fontWeight || "normal"};
  font-size: 1.5rem;
  @media screen and (max-width: 1100px) {
    font-size: 1.35rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.05rem;
  }
`;
export const Label = styled.label`
  color: ${(props) => props.color || "black"};
  font-weight: ${(props) => props.fontWeight || "normal"};
  font-size: 1rem;
  @media screen and (max-width: 1100px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.7rem;
  }
`;
export const LabelS = styled.label`
  color: ${(props) => props.color || "black"};
  font-weight: ${(props) => props.fontWeight || "normal"};
  font-size: 0.9rem;
  @media screen and (max-width: 1100px) {
    font-size: 0.81rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.72rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.63rem;
  }
`;
export const LabelXS = styled.label`
  color: ${(props) => props.color || "black"};
  font-weight: ${(props) => props.fontWeight || "normal"};
  font-size: 0.7rem;
  @media screen and (max-width: 1100px) {
    font-size: 2.5;
  }

  @media screen and (max-width: 768px) {
    font-size: 2;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.5;
  }
`;
export const LabelXXS = styled.label`
  color: ${(props) => props.color || "black"};
  font-weight: ${(props) => props.fontWeight || "normal"};
  font-size: 0.7rem;
  @media screen and (max-width: 1100px) {
    font-size: 0.7rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.7rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.7rem;
  }
`;

export const LimitedContainer = styled.div`
  width: 40vw;
  display: flex;
  @media screen and (max-width: 960px) {
    width: 90vw;
  }
`;
export const LimitedContainerLarge = styled.div`
  width: 60vw;
  display: flex;

  ${(props) =>
    props.sizing !== false &&
    `
     @media screen and (max-width: 960px) {
    width: 90vw;
  }
  `}
`;
export const LimitedContainerMedium = styled.div`
  width: 50vw;
  display: flex;
  @media screen and (max-width: 960px) {
    width: 90vw;
  }
`;

export const ResponsiveIcon = styled.div`
  display: inline-flex;
  svg {
    width: ${(props) => props.size || "3rem"};
    height: ${(props) => props.size || "3rem"};
  }

  /* Media Queries for Responsiveness */
  @media screen and (max-width: 1100px) {
    svg {
      width: ${(props) =>
        props.size ? `calc(${props.size} * 0.8)` : "2.4rem"};
      height: ${(props) =>
        props.size ? `calc(${props.size} * 0.8)` : "2.4rem"};
    }
  }

  @media screen and (max-width: 768px) {
    svg {
      width: ${(props) =>
        props.size ? `calc(${props.size} * 0.7)` : "2.1rem"};
      height: ${(props) =>
        props.size ? `calc(${props.size} * 0.7)` : "2.1rem"};
    }
  }

  @media screen and (max-width: 480px) {
    svg {
      width: ${(props) =>
        props.size ? `calc(${props.size} * 0.6)` : "1.8rem"};
      height: ${(props) =>
        props.size ? `calc(${props.size} * 0.6)` : "1.8rem"};
    }
  }
`;

export const Image = styled.img`
  width: 30rem;
  border-radius: 1rem;
  align-self:  center;
  height: ${({ height }) => height || 'auto'};
  object-fit: ${({ fit }) => fit || 'cover'};
  @media screen and (max-width: 1100px) {
    width: 18rem;
  }
`;

export const ImageSmall = styled.img`
  width: 10rem;
  border-radius: 1rem;
  @media screen and (max-width: 1100px) {
    width: 7rem;
  }
`;

export const CustomMarquee = styled(Marquee)`
  opacity: 0.5;
  margin: '0 3px';
  margin-top: 5rem;
  position: absolute;
`;

export const PriceTable = styled.table`
  width: 100%;
  border-collapse: collapse; /* Change to separate to allow rounded corners */
  margin: 20px 0;
  border-radius: 1rem; /* Set border radius for the table */
  background-color: white; /* Set background to white */
  overflow: hidden; /* Hide any overflow for the rounded corners */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Subtle shadow */
`;

export const TableHeader = styled.th`
  padding: 12px 15px;
  background-color: #f4f4f4;
  text-align: left;
  font-weight: bold;
  color: black; /* Text color for headers */
`;

export const TableRow = styled.tr`
  background-color:rgb(255, 255, 255);
  &:nth-child(even) {
    background-color:rgb(226, 226, 226);
  }
`;

export const TableCell = styled.td`
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
  border-color: transparent;
  color: black; /* Text color for table cells */
`;


