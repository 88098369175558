import { Helmet } from 'react-helmet-async';
import { ColumnContainer, Label, Panel, SafePanel } from "../components/Ui/Ui";
const Delivery = () => {
  return (
    <>
      <Helmet>
        <title>Lieferung | LTM</title>
        <meta name="description" content="Entdecken Sie den Lieferdienst von LTM! Egal ob Haushaltsgeräte, Möbel oder andere Gegenstände – wir liefern zuverlässig und pünktlich an Ihr Ziel. " />
      </Helmet>
      {/* <DeliveryComp data={deliveryPageData} dataCar={deliveryPageCarData} dataPrice={deliveryPageCarPriceData}/> */}
      <Panel style={{ background: "white" }}>
        <SafePanel>
          <ColumnContainer>
            <Label
              style={{ color: "black", fontWeight: "900", fontSize: "3rem" }}
            >
              Wir bieten Lieferung an!
            </Label>
          </ColumnContainer>
        </SafePanel>
      </Panel>
      <Panel style={{ background: "black" }}>
        <SafePanel>
          <ColumnContainer>
            <Label
              style={{ color: "white", fontWeight: "900", fontSize: "3rem" }}
            >
              Was wir lieferen
            </Label>
          </ColumnContainer>
        </SafePanel>
      </Panel>
      <Panel style={{ background: "red" }}>
        <SafePanel>
          <ColumnContainer>
            <Label
              style={{ color: "white", fontWeight: "900", fontSize: "3rem" }}
            >
              Unsere Preisliste
            </Label>
          </ColumnContainer>
        </SafePanel>
      </Panel>
    </>
  );
};

export default Delivery;