import { Icons } from "../components/Icons/Icons";

export const contactPageMainData = {
    title: "Sprechen Sie mit uns – Wir helfen Ihnen gerne",
    description:
      "Möchten Sie einen Transporter mieten, Ihr Auto reparieren lassen oder es selbst reparieren? Haben Sie Fragen oder Anregungen? Wir bieten Ihnen verschiedene Möglichkeiten, mit uns in Kontakt zu treten. Sie können uns bequem per E-Mail, Telefon, über WhatsApp oder unser Kontaktformular erreichen. Alternativ können Sie uns während unserer Öffnungszeiten direkt an unserem Standort besuchen. Wir sind stets bereit, Ihnen weiterzuhelfen und freuen uns auf Ihre Anfrage!",
    info:[
        {
            title:"Telefon & Whatsapp",
            description:"015780456850",
            icon:Icons.call
        },
        {
            title:"E-Mail",
            description:"info@ltm-hl.de",
            icon:Icons.email
        },
        {
            title:"Standort",
            description:"Hinter den Kirchkaten 35, 23560 Lübeck (Eingang: Henschelstraße)",
            icon:Icons.location
        },
    ]
    };
  
  
  export const contactPageSecondData = {
    title: "Standort",
    src:"./assets/images/location/location.png",
  };

  export const contactPageKontaktFormData = {
    ready:"Bald verfügbar!",
    title: "Kontaktformular",
    name:"Name*",
    email:"E-Mail Adresse*",
    msg:"Ihre Nachricht*",
    tip:"Mit dem Klick auf den Button 'Absenden' stimme ich zu, dass meine Angaben und Daten zur Beantwortung meiner Anfrage elektronisch erhoben und gespeichert werden.",
    btn:"Absenden"
  };