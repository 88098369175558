export const creditsPageMainData = {
  title: "Verwendete Ressourcen und Danksagungen",
  description:
    "Der Zweck dieser Seite ist es, alle Ressourcen, die auf unserer Webseite verwendet wurden, transparent zu erwähnen und den entsprechenden Urhebern die gebührende Anerkennung zu geben. Sollten wir dabei versehentlich eine Quelle übersehen oder fehlerhafte Angaben gemacht haben, bitten wir Sie, uns zu kontaktieren. Wir werden die Angaben umgehend prüfen und entsprechend ergänzen. Vielen Dank für Ihr Verständnis und Ihre Unterstützung!",
};

export const creditsPageIconData = {
  title: "Icons",
  name: "Freepik",
  website: "https://www.freepik.com/",
  authors: [
    // {
    //   name: "Freepik",
    //   link: "https://www.flaticon.com/authors/freepik",
    // },
    // {
    //   name: "Smashicons",
    //   link: "https://www.flaticon.com/authors/smashicons",
    // },
  ],
};

export const creditsPageImagesData = {
    title: "Bilder",
    name: "Unsplash",
    website: "https://unsplash.com",
    authors: [
        {
          name: "Jane Doe",
          link: "https://unsplash.com/@janedoe",
        },
        {
          name: "PhotoHub",
          link: "https://unsplash.com/@photohub",
        },
      ],
  };

  export const creditsPageAnimationsData = {
    title: "Animationen",
    website: "https://lottiefiles.com",
    authors: [
        {
          name: "Jane Doe",
          link: "https://unsplash.com/@janedoe",
        },
        {
          name: "PhotoHub",
          link: "https://unsplash.com/@photohub",
        },
      ],
  };
  
  export const creditsPageFontsData =  {
    title: "Fonts",
    website: "https://fonts.google.com",
    authors: [
      {
        name: "Google Fonts Team",
        link: "https://fonts.google.com/about",
      },
    ],
  };
