import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from 'react-icons/fa';

const iconStyle = (Icon) => <Icon />;

export const footerSocialData = [
	{
		name: 'Facebook',
		icon: iconStyle(FaFacebook),
	},
];

export const footerData = [
	{
		title: 'Main',
		links: ['Blog', 'FAQs', 'Support', 'About us'],
	},
	{
		title: 'Product',
		links: ['Login', 'Personal', 'Business', 'Team'],
	},
	{
		title: 'Press',
		links: ['Logos', 'Events', 'Stories', 'Office'],
	},
	{
		title: 'Legal',
		links: ['GDPR', 'Privacy Policy', 'Terms of Service', 'Disclaimer'],
	},
];

export const data = [
	{
	  to: "/",
	  text: "Startseite",
	  id: "startseite",
	},
	{
	  to: "/rent",
	  text: "Mieten",
	},
	{
	  to: "/kfz",
	  text: "KFZ",
	},
	{
	  to: "/diy",
	  text: "Selbsthilfewerkstatt",
	},
	{
	  to: "/contact",
	  text: "Kontakt",
	},
	{
	  to: "/aboutus",
	  text: "Über uns",
	},
	{
		to: "/credits",
		text: "Credits",
	  },
  ];
  