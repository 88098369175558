import React from "react";
import { useState, useEffect  } from "react";
import { FaTimes, FaBars } from "react-icons/fa";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavIcon,
  MobileIcon,
  NavMenu,
  NavLinks,
  NavItem,
} from "./NavBarStyles.js";
import { useLocation, useNavigate } from "react-router-dom";
import { data } from "../../data/NavBarData";
import { srcData } from "../../data/SrcData.js";
const Navbar = () => {
  const [show, setShow] = useState(null);
  const [hasResized, setHasResized] = useState(false); // Track resize state


  let navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    // Function to handle resizing
    const handleResize = () => {
      if (window.innerWidth <= 960 && !hasResized) {
        setShow(null); // Set the initial state to null on first resize
        setHasResized(true); // Mark that the resize action has been done
      } else if (window.innerWidth > 960 && hasResized) {
        // Reset the resize state when the width goes back above 960px
        setHasResized(false);
        document.body.style.overflow = "";
      }
    };

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Call handleResize once to check initial screen width
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [hasResized])

  const handleClick = () => {
    setShow(!show);
    if (!show) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = ""; // Enable scrolling (reset to default)
    }
  };

  const scrollTo = (id) => {
    const element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  const closeMobileMenu = (to, id) => {
    if (id && location.pathname === "/") {
      //scrollTo(id);
    }
    document.body.style.overflow = "";
    navigate(to);
    setShow(false);
  };

  return (
    <Nav>
      <NavbarContainer>
        <NavLogo to="/">
          <NavIcon src={srcData.logo.localuri} alt="logo" />
        </NavLogo>
        <MobileIcon onClick={handleClick}>
          {show ? <FaTimes color="white" /> : <FaBars color="white" />}
        </MobileIcon>
        <NavMenu show={show}>
          {data.map((el, index) => {
            if (!el.text) return null; // Skip rendering if el.text is empty or falsy
            return (
              <NavItem key={index}>
                <NavLinks onClick={() => closeMobileMenu(el.to, el.id)}>
                  {el.text}
                </NavLinks>
              </NavItem>
            );
          })}
        </NavMenu>
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;
