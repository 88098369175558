import {
  SelbstHilfeWerkstattBedingungen,
  selfKfzPageMainData,
  selfKfzPageSecondData,
  selfKfzPageCarPriceData,
  selfKfzBedingungPageData,
} from "../data/SelfKfzPageData";
import { Helmet } from "react-helmet-async";
import {
  ColumnContainer,
  Label,
  Panel,
  SafePanel,
  LimitedContainer,
  Space,
  Button,
  RowContainer,
  Image,
  ContainerButton,
  ScrollContainer,
  LimitedContainerMedium,
  LabelXXL,
  LabelL,
  LabelS,
  ResponsiveIcon,
  TableRow,
  TableCell,
  PriceTable,
} from "../components/Ui/Ui";
import { useNavigate } from "react-router-dom";
import { NavToContactPage, scrollToTop } from "../utils/utilities";
import { dataByKey } from "../data/NavBarData";
import { SelfKfzPageMetaData } from "../data/MetaData";
import { srcData } from "../data/SrcData";
const SelfKfz = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>{SelfKfzPageMetaData.title}</title>
        {SelfKfzPageMetaData.meta.map((meta, index) => (
          <meta
            key={index}
            {...(meta.property
              ? { property: meta.property }
              : { name: meta.name })}
            content={meta.content}
          />
        ))}
        <link rel="canonical" href={SelfKfzPageMetaData.canonical} />
        <script type="application/ld+json">
          {JSON.stringify(SelfKfzPageMetaData.structuredData)}
        </script>
      </Helmet>
      <Panel style={{ background: "white" }}>
        <SafePanel normal={false} allowMargin={false}>
          <ColumnContainer>
            <LimitedContainerMedium>
              <LabelXXL
              color="black" fontWeight="900"
              >
                {selfKfzPageMainData.title}
              </LabelXXL>
            </LimitedContainerMedium>
            <LimitedContainer >
              <Label style={{ color: "black" }}>
                {selfKfzPageMainData.description}
              </Label>
            </LimitedContainer>
            <Space style={{ height: "2rem" }} />
            <LimitedContainer>
              <Button
                onClick={() => {
                  NavToContactPage(navigate, dataByKey.contact.to);
                  scrollToTop();
                }}
              >
                {selfKfzPageMainData.btn}
              </Button>
            </LimitedContainer>
          </ColumnContainer>
        </SafePanel>
      </Panel>
      <Panel style={{ background: "black" }}>
        <SafePanel>
          <ColumnContainer style={{ width: "100%" }}>
            <RowContainer sizing={false}>
              <LabelXXL
            color="white" fontWeight="900"
              >
                {selfKfzPageSecondData.title}
              </LabelXXL>
              <Space style={{ width: "0.5rem" }} />
                 {(() => {
                  const Icon = selfKfzPageSecondData.icon;
                              return (
                                 <ResponsiveIcon size="4rem">
                                <Icon fill="white" viewBox="-2 -2 28 28" style={{alignSelf:"center", strokeWidth:"1", stroke:"white" }}/>
                                </ResponsiveIcon>
                                );
                            })()}
            </RowContainer>
            <Space style={{height:"2rem"}}/>
            <ColumnContainer
              style={{ height: "100%", justifyContent: "center" }}
            >
              <RowContainer style={{ justifyContent: "center", gap: "3rem" }}>
                <ColumnContainer style={{gap:"1rem"}}>
                  {selfKfzPageSecondData.infos.map((element, index) => (
                    <ColumnContainer key={index}>
                    <LimitedContainer>
                      <LabelL
                         color="white" fontWeight="600"
                      >
                        {element.title}
                      </LabelL>
                      </LimitedContainer>
                      <LimitedContainer>
                        <Label  color="white">
                          {element.description}
                        </Label>
                        </LimitedContainer>
                    </ColumnContainer>
                  ))}
                </ColumnContainer>
                <ColumnContainer style={{ justifyContent: "center" }}>
                  <Image
                    src={srcData.kfz1.localuri}
                    alt="Transporter"
                  />
                </ColumnContainer>
              </RowContainer>
            </ColumnContainer>
          </ColumnContainer>
        </SafePanel>
      </Panel>
      <Panel style={{ background: "red" }}>
        <SafePanel>
          <ColumnContainer>
          <RowContainer sizing={false}>
            <LabelXXL
            color="white" fontWeight="900"
            >
              {selfKfzPageCarPriceData.title}
            </LabelXXL>
            <Space style={{ width: "0.5rem" }} />
                {(() => {
                  const Icon = selfKfzPageCarPriceData.icon;
                              return (
                                 <ResponsiveIcon size="4rem">
                                <Icon fill="white" viewBox="-2 -2 28 28" style={{alignSelf:"center", strokeWidth:"1", stroke:"white" }}/>
                                </ResponsiveIcon>
                                );
                            })()}
            </RowContainer>
            <Space style={{ height: "3rem" }} />
            <Label color="white">
              {selfKfzPageCarPriceData.description}
            </Label>
            <Space style={{ height: "2rem" }} />
            <RowContainer style={{ justifyContent: "center", gap: "2rem", alignItems:"center" }}>
            <ColumnContainer>
                              <LabelL
                                color="white"
                                fontWeight="900"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {selfKfzPageCarPriceData.wochentariftitle}
                              </LabelL>
                              <Label
                                color="white"
                                fontWeight="normal"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {selfKfzPageCarPriceData.wochentarif}
                              </Label>
                              <PriceTable>
                                {selfKfzPageCarPriceData.wochenTarif.map((item, index) => (
                                  <TableRow key={index}>
                                  <TableCell>{item.description}</TableCell>
                                    <TableCell>{item.art}</TableCell>
                                    <TableCell>{item.kilometer}</TableCell>
                                    <TableCell>{item.price}</TableCell>
                                  </TableRow>
                                ))}
                              </PriceTable>
                              <RowContainer style={{ flexDirection: "row-reverse" }}>
                                <LabelS
                                  color="white"
                                  fontWeight="600"
                                  style={{
                                    opacity: "0.3",
                                    marginRight: "0.5rem",
                                  }}
                                >
                                  {selfKfzPageCarPriceData.standNo}
                                </LabelS>
                              </RowContainer>
                            </ColumnContainer>
                            <ColumnContainer>
                              <LabelL
                                color="white"
                                fontWeight="900"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {selfKfzPageCarPriceData.wochenendtariftitle}
                              </LabelL>
                              <Label
                                color="white"
                                fontWeight="normal"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {selfKfzPageCarPriceData.wochenendtarif}
                              </Label>
                              <PriceTable>
                                {selfKfzPageCarPriceData.wochenEndtarif.map((item, index) => (
                                  <TableRow key={index}>
                                  <TableCell>{item.description}</TableCell>
                                    <TableCell>{item.art}</TableCell>
                                    <TableCell>{item.kilometer}</TableCell>
                                    <TableCell>{item.price}</TableCell>
                                  </TableRow>
                                ))}
                              </PriceTable>
                              <RowContainer style={{ flexDirection: "row-reverse" }}>
                                <LabelS
                                  color="white"
                                  fontWeight="600"
                                  style={{
                                    opacity: "0.3",
                                    marginRight: "0.5rem",
                                  }}
                                >
                                  {selfKfzPageCarPriceData.standEn}
                                </LabelS>
                              </RowContainer>
                            </ColumnContainer>
            </RowContainer>

            <Space style={{ height: "2rem" }} />
            {selfKfzPageCarPriceData.info.map((element, index) => (
              <LabelS
              color="white" fontWeight="600"
                style={{
                  opacity: "0.3",
                }}
                key={index}
              >
                {element.title}
              </LabelS>
            ))}
            <Space style={{ height: "12rem" }} />
            <RowContainer sizing={false}>
            <LabelXXL
            color="white" fontWeight="900"
            
            >
              {selfKfzBedingungPageData.title}
            </LabelXXL>
            <Space style={{ width: "0.5rem" }} />

                {(() => {
                  const Icon = selfKfzBedingungPageData.icon;
                              return (
                                 <ResponsiveIcon size="4rem">
                                <Icon fill="white" viewBox="-2 -2 28 28" style={{alignSelf:"center", strokeWidth:"1", stroke:"white" }}/>
                                </ResponsiveIcon>
                                );
                            })()}
            </RowContainer>
            <Space style={{ height: "3rem" }} />
            <ScrollContainer>
              {SelbstHilfeWerkstattBedingungen.map((section, index) => (
                <ColumnContainer key={index}>
                  <LabelL
                    key={index}
                    color="white"
                    fontWeight="900"
                    style={{
                      fontSize: "1.5rem",
                    }}
                  >
                    {section.title}
                  </LabelL>
                  {section.points.map((point, idx) => (
                    <Label
                      key={idx}
                      color="white"
                    >
                      {point}
                    </Label>
                  ))}
                  <Space style={{height:"1rem"}}/>
                </ColumnContainer>
              ))}
            </ScrollContainer>
          </ColumnContainer>
        </SafePanel>
      </Panel>
    </>
  );
};

export default SelfKfz;
