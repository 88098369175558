import styled from "styled-components";
import { Link } from "react-router-dom";

export const Nav = styled.nav`
  background: black;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: absolute;
  top: 0;
  z-index: 50;
  width: 100%;
  transition: background-color 0.3s ease-in;
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: start;
  height: 80px;
  width: 69%;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const NavLogo = styled(Link)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  z-index: 50;
  @media screen and (max-width: 960px) {
    margin-left: 1.1rem;
  }
`;

export const NavIcon = styled.img`
  margin-right: 1rem;
  width: 4rem;
`;

export const MobileIcon = styled.div`
  display: none;
  z-index: 50;
  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  background: black;
  align-items: center;
  list-style: none;
  text-align: center;
  width: 100%;
  transition: opacity 0.5s ease;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: fixed;
    padding-top: 5rem;
    top: 0;
    left: 0;
    opacity: ${({ show }) => (show ? 1 : 0)};
    visibility: ${({ show }) => (show ? "visible" : "hidden")};
    /* transform: translateY(${({ show }) => (show ? "0" : "-10px")}); */
    transition: opacity 0.5s ease;
    background: black;
    ${(props) =>
    props.show == false &&
    props.show !== undefined &&
    props.show !== null &&
    `
    transition: opacity 0.5s ease, visibility 0s linear 0.5s;
  `}
  }
  > li:first-child {
    margin-left: auto;
  }
`;

export const NavItem = styled.li`
  height: 4rem;
  background: black;
  cursor: pointer;
  @media screen and (max-width: 960px) {
    width: 100%;
    background: black;
    &:hover {
      border: none;
    }
  }
`;

export const NavLinks = styled.span`
  color: #E2E2E2;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  &:hover {
    color: white;
    transition: all 0.3s ease;
  }
  @media screen and (max-width: 960px) {
    text-align: left;
    padding: 1rem;
    width: 100%;
    display: table;
    &:hover {
      color: white;
      transition: all 0.3s ease;
    }
  }
`;

