export const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };


  export const NavToContactPage = (navigate, direction) => {
    navigate(direction);
  };